import {useMemo, useRef} from "react";

import {AlignHorizontal, AlignVertical} from "common/components/table/ApiDrivenTable/index";

import {FleetOwnerPortalService} from "@bolteu/bolt-server-api-fleet-owner-portal";
import {Table, Tooltip} from "@bolteu/kalep-react";
import {InfoCircle} from "@bolteu/kalep-react-icons";

import {
    CellProps,
    CellRenderer,
    generateSimpleKey,
    isColumnVisible,
    isDateColumn,
    isReferenceColumn,
    ReferenceCellRenderer,
    SubContentRenderer,
    UseableColumns,
} from "./Common";
import ApiColumn = FleetOwnerPortalService.ApiColumn;
import StateColumn = FleetOwnerPortalService.StateColumn;

type ApiDrivenTableDesktopProps = UseableColumns & {
    numberOfRecords: number;
    alignVertical?: AlignVertical;
    alignHorizontal?: AlignHorizontal;
};

export default function ApiDrivenTableDesktop(apiDrivenTableDesktopProps: ApiDrivenTableDesktopProps) {
    const {
        subColumns,
        stateColumns,
        alignVertical = AlignVertical.MIDDLE,
        alignHorizontal = AlignHorizontal.LEFT,
        userDefinedVisibleColumns,
    } = apiDrivenTableDesktopProps;

    const refs = useRef<(HTMLElement | null)[]>([]);

    const addRef = useMemo(
        () => (idx: number) => (r: HTMLElement | null) => {
            refs.current[idx] = r;
        },
        [],
    );

    const getSubColumns = useMemo(
        () =>
            userDefinedVisibleColumns.reduce((acc, column) => {
                acc[column.key] = subColumns.filter((c) => c.relation_key === column.key);
                return acc;
            }, {} as Record<string, FleetOwnerPortalService.ApiColumn[]>),
        [userDefinedVisibleColumns, subColumns],
    );

    return (
        <Table>
            <Table.Header>
                <Table.HeaderRow>
                    {userDefinedVisibleColumns.map((column, columnIdx) => (
                        <Table.HeaderCell align={alignHorizontal} key={column.key} ref={addRef(columnIdx)}>
                            <div
                                className={`align-center flex h-full w-full items-center ${
                                    !isReferenceColumn(column) &&
                                    !isDateColumn(column) &&
                                    alignHorizontal === AlignHorizontal.RIGHT
                                        ? "justify-end"
                                        : "justify-start"
                                } gap-x-1`}
                            >
                                {column.title}
                                {column.tooltip && (
                                    <Tooltip
                                        placement="left"
                                        key={column.key}
                                        content={column.tooltip}
                                        boundaryElement={refs.current[columnIdx] ?? undefined}
                                    >
                                        <div>
                                            <InfoCircle width={14} height={14} />
                                        </div>
                                    </Tooltip>
                                )}
                            </div>
                        </Table.HeaderCell>
                    ))}
                </Table.HeaderRow>
            </Table.Header>
            <Table.Body>
                {Array.from({length: apiDrivenTableDesktopProps.numberOfRecords}).map((_, rowIdx) => (
                    <Table.Row key={generateSimpleKey(rowIdx)}>
                        {userDefinedVisibleColumns.filter(isColumnVisible).map((column) => (
                            <Cell
                                column={column}
                                valueAtIdx={rowIdx}
                                stateColumns={stateColumns}
                                alignVertical={alignVertical}
                                alignHorizontal={alignHorizontal}
                                subColumns={getSubColumns[column.key]}
                                key={`${column.key} - ${column.cells[rowIdx]}`}
                                isReferenceCell={isReferenceColumn(column)}
                            />
                        ))}
                    </Table.Row>
                ))}
            </Table.Body>
        </Table>
    );
}

function Cell(
    cellProps: CellProps & {
        subColumns: ApiColumn[];
        isReferenceCell?: boolean;
        stateColumns?: StateColumn[];
        alignVertical?: AlignVertical;
        alignHorizontal?: AlignHorizontal;
    },
): JSX.Element {
    const stateColumn = cellProps.stateColumns?.find((sc) => sc.relation_key === cellProps.column.key);
    const verticalAlignStyle = cellProps.alignVertical === AlignVertical.TOP ? "content-start align-top" : "";
    const referenceCellStyle = `${verticalAlignStyle} text-left`;

    if (cellProps.isReferenceCell) {
        return (
            <Table.Cell overrideClassName={referenceCellStyle}>
                <ReferenceCellRenderer {...cellProps} stateColumn={stateColumn} displayAsLink />
                <SubContentRenderer {...cellProps} />
            </Table.Cell>
        );
    }

    return (
        <Table.Cell overrideClassName={verticalAlignStyle} align={cellProps.alignHorizontal}>
            <CellRenderer {...cellProps} />
            <SubContentRenderer {...cellProps} />
        </Table.Cell>
    );
}
