import {FC, useCallback, useContext, useEffect, useMemo, useState} from "react";

import {useEntityDocumentFunctions} from "common/components/document/entity/useEntityDocumentFunctions";
import {NotificationType} from "common/components/notifications/TopLeftCorner";
import {EventName} from "common/constants/events";
import {FetchStatus, useFetch} from "common/hooks/useFetch";
import {useI18n} from "common/hooks/useI18n";
import {useTracking} from "common/hooks/useTracking";
import {NotificationContext} from "common/services/notificationProvider";
import {getIsoDate} from "common/util/FormatUtil";

import {Select, SelectOption} from "@bolteu/kalep-react";

import {DocumentData, DocumentOwner} from "../useDocumentsList";
import {UploadDocument} from "./UploadDocument";

export interface UploadDocumentWithDocumentTypeProps {
    owner: DocumentOwner;
    onClose: ({documentUploaded}: {documentUploaded: boolean}) => void;
    documentTypes: DocumentData[];
    documentTypeId: number | null;
}

const UploadDocumentWithDocumentType: FC<UploadDocumentWithDocumentTypeProps> = ({
    owner,
    onClose,
    documentTypeId,
    documentTypes,
}) => {
    const {i18n} = useI18n();
    const {trackEvent} = useTracking();

    const {setNotification} = useContext(NotificationContext);

    const [selectedDocumentTypeOption, setSelectedDocumentTypeOption] = useState<SelectOption | null>(null);

    const {documentUploadFunction} = useEntityDocumentFunctions(owner);
    const {fetch: uploadDocument, status: uploadDocumentStatus, error} = useFetch(documentUploadFunction);

    const selectedDocumentType = useMemo(
        () => documentTypes.find((type) => type.type.id === selectedDocumentTypeOption?.value)?.type,
        [documentTypes, selectedDocumentTypeOption],
    );

    useEffect(() => {
        if (uploadDocumentStatus === FetchStatus.Success) {
            // Document was uploaded, have to close. If no notification then be it. Should not happen
            setNotification({
                type: NotificationType.SUCCESS,
                text: i18n("upload_document.document_uploaded"),
                timeout: 6000,
            });

            if (selectedDocumentType?.name) {
                trackEvent(EventName.DocumentUploadSuccessful, {documentType: selectedDocumentType.name});
            }

            onClose({documentUploaded: true});
        }
    }, [i18n, trackEvent, setNotification, onClose, uploadDocumentStatus, selectedDocumentType?.name]);

    useEffect(() => {
        // ... && error.message, because waiting for the useFetch to show error message then close
        if (uploadDocumentStatus === FetchStatus.Error && error.message) {
            if (selectedDocumentType?.name) {
                trackEvent(EventName.DocumentUploadFailed, {documentType: selectedDocumentType.name});
            }

            onClose({documentUploaded: false});
        }
    }, [i18n, trackEvent, onClose, uploadDocumentStatus, selectedDocumentType?.name, error.message]);

    const documentTypeOptions = useMemo(
        () =>
            documentTypes
                .map((val) => ({value: val.type.id, title: val.type.title}))
                .sort((a, b) => a.title.localeCompare(b.title)),
        [documentTypes],
    );

    useEffect(() => {
        if (documentTypeId) {
            const documentTypeOption = documentTypeOptions.find(({value}) => value === documentTypeId);
            setSelectedDocumentTypeOption(documentTypeOption ?? null);
        }
    }, [documentTypeId, documentTypeOptions]);

    const onDocumentTypeChange = useCallback((newValue: SelectOption | SelectOption[] | null) => {
        if (Array.isArray(newValue)) {
            setSelectedDocumentTypeOption(newValue[0]);
        } else {
            setSelectedDocumentTypeOption(newValue);
        }
    }, []);

    const onConfirmClick = useCallback(
        (selectedFile: File | null, selectedExpiryDate: Date | null) => {
            const isExpiryDateRequiredAndSet = selectedDocumentType?.is_expiry_required && !selectedExpiryDate;
            const isFileAndDocumentTypeSelected = selectedFile === null || selectedDocumentType === undefined;

            if (!uploadDocument || isFileAndDocumentTypeSelected || isExpiryDateRequiredAndSet) {
                return;
            }

            let expiresIsoDate = null;
            if (selectedDocumentType.is_expiry_required && selectedExpiryDate) {
                expiresIsoDate = getIsoDate(selectedExpiryDate);
            }
            const files = {[selectedDocumentType.name]: selectedFile};
            uploadDocument(expiresIsoDate, files);
        },
        [selectedDocumentType, uploadDocument],
    );

    const isDisabledFn = useCallback(
        (selectedFile: File | null, selectedExpiryDate: Date | null) => {
            const isExpiryDateRequiredAndSet = selectedDocumentType?.is_expiry_required && !selectedExpiryDate;
            const isFileAndDocumentTypeSelected = selectedFile === null || selectedDocumentType === undefined;

            return isExpiryDateRequiredAndSet || isFileAndDocumentTypeSelected;
        },
        [selectedDocumentType],
    );

    return (
        <UploadDocument
            onConfirmClick={onConfirmClick}
            onClose={onClose}
            showExpiryDatePicker={selectedDocumentType?.is_expiry_required}
            isDisabledFn={isDisabledFn}
            isLoading={uploadDocumentStatus === FetchStatus.Loading}
        >
            <Select
                value={selectedDocumentTypeOption}
                options={documentTypeOptions}
                onChange={onDocumentTypeChange}
                label={i18n("upload_document.document_type")}
                placeholder={i18n("upload_document.select_type")}
                fullWidth
            />
        </UploadDocument>
    );
};

export {UploadDocumentWithDocumentType};
