import {useTailwindViewport} from "common/hooks/useTailwindViewport";

import {FleetOwnerPortalService} from "@bolteu/bolt-server-api-fleet-owner-portal";

import ApiDrivenTableDesktop from "./ApiDrivenTableDesktop";
import {ApiDrivenTableMobile} from "./ApiDrivenTableMobile";
import {useApiDrivenTableColumns} from "./Common";

export enum AlignHorizontal {
    RIGHT = "right",
    LEFT = "left",
}

export enum AlignVertical {
    TOP = "top",
    MIDDLE = "middle",
}

interface ApiDrivenTableProps {
    columns: FleetOwnerPortalService.ApiColumn[];
    numberOfRecords: number;
    alignHorizontal?: AlignHorizontal;
    alignVertical?: AlignVertical;
    tableId: string;
}

export default function ApiDrivenTable(apiDrivenTableProps: ApiDrivenTableProps) {
    const columns = useApiDrivenTableColumns(apiDrivenTableProps.tableId, apiDrivenTableProps.columns);

    const viewport = useTailwindViewport();
    const isMobileView = viewport === "sm";

    if (isMobileView) {
        return <ApiDrivenTableMobile {...columns} numberOfRecords={apiDrivenTableProps.numberOfRecords} />;
    }

    return (
        <ApiDrivenTableDesktop
            {...columns}
            alignVertical={apiDrivenTableProps.alignVertical}
            alignHorizontal={apiDrivenTableProps.alignHorizontal}
            numberOfRecords={apiDrivenTableProps.numberOfRecords}
        />
    );
}
