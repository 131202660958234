import {createContext, useCallback, useRef, useState} from "react";
import {GoogleReCaptchaProvider} from "react-google-recaptcha-v3";
import {useIntl} from "react-intl";
import {useNavigate, useParams} from "react-router-dom";

import {UnreachableCode} from "common/components/util/UnreachableCode";
import {VerificationKeyMinLength} from "common/constants/routes";
import {useI18n} from "common/hooks/useI18n";
import {ResponseCodes} from "common/ResponseCodes";
import {FleetOwnerVerificationNoAuthApiClient} from "common/services/apiClients/noAuthApiClient";
import {RefreshToken} from "common/services/authService";
import {localStorageService} from "common/services/LocalStorageService";
import {browserInfo} from "common/util/BrowserInfo";
import {isCaptchaError, isServerError, isServerErrorWithData} from "common/util/isErrorType";
import config from "config/index";
import {useFleetOwnerForSignup} from "features/account/components/Verification/hooks/useFleetOwnerForSignup";
import {useFleetOwnerForSignupResponse} from "features/account/components/Verification/hooks/useFleetOwnerForSignupResponse";
import {useVerificationNavigations} from "features/account/components/Verification/hooks/useVerificationNavigations";

import {
    FleetOwnerVerificationNoAuthService,
    FleetOwnerVerificationService,
} from "@bolteu/bolt-server-api-fleet-owner-portal";
import {useSnackbar} from "@bolteu/kalep-react";
import VerificationChannel = FleetOwnerVerificationNoAuthService.VerificationChannel;
import FopVerificationChannel = FleetOwnerVerificationNoAuthService.FopVerificationChannel;
import VerificationCodeData = FleetOwnerVerificationNoAuthService.VerificationCodeData;
import VerificationChallengeSuccessType = FleetOwnerVerificationNoAuthService.FopVerificationChallengeSuccessType;
import ChallengeToken = FleetOwnerVerificationNoAuthService.ChallengeToken;
import CaptchaChallengeType = FleetOwnerVerificationNoAuthService.CaptchaChallengeType;

type RequestOtpResponse =
    | FleetOwnerVerificationNoAuthService.OtpRequestedResponse
    | FleetOwnerVerificationNoAuthService.ContinueSignupResponse
    | FleetOwnerVerificationNoAuthService.TwoFactorAuthRequiredResponse
    | FleetOwnerVerificationNoAuthService.TwoFactorConfirmationRequiredResponse
    | FleetOwnerVerificationNoAuthService.UseSignupResponse
    | FleetOwnerVerificationNoAuthService.ContinueAuthResponse
    | FleetOwnerVerificationNoAuthService.UseAuthResponse
    | FleetOwnerVerificationNoAuthService.ContinueCompanyRegistrationStepsResponse
    | FleetOwnerVerificationNoAuthService.ContinueCompanyRegistrationOnboardingPageResponse
    | FleetOwnerVerificationNoAuthService.AddNewCompanyRegistrationResponse;

export interface CaptchaData {
    type: CaptchaChallengeType;
    siteKey: string;
}

interface RegularVerificationChallenge {
    challenge?: ChallengeToken;
    channel: FopVerificationChannel;
}

interface CaptchaVerificationChallenge {
    challenge: ChallengeToken;
    channel?: FopVerificationChannel;
}

export interface VerificationState {
    otpData: VerificationCodeData | null;
    requestCodeError: string | undefined;
    isLoading: boolean;
    confirmCodeError: string | undefined;
    captchaData: CaptchaData | null;
    setConfirmCodeError: (error: string | undefined) => void;
    setRequestCodeError: (error: string | undefined) => void;
    onPageLoad: () => void;
    requestCode: (data: RegularVerificationChallenge | CaptchaVerificationChallenge) => void;
    confirmCode: (otpCode: string) => void;
}

const defaultState: VerificationState = {
    otpData: null,
    requestCodeError: undefined,
    isLoading: true,
    confirmCodeError: undefined,
    captchaData: null,
    setConfirmCodeError: () => {},
    setRequestCodeError: () => {},
    onPageLoad: () => {},
    requestCode: () => {},
    confirmCode: () => {},
};

const VerificationContextProvider = createContext<VerificationState>(defaultState);
VerificationContextProvider.displayName = "VerificationContextProvider";

const VerificationProvider = ({children}: {children: React.ReactNode}) => {
    const intl = useIntl();
    const {i18n} = useI18n();
    const snackbar = useSnackbar();
    const [isLoading, setIsLoading] = useState(true);
    const [requestCodeError, setRequestCodeError] = useState<string | undefined>(undefined);
    const [captchaData, setCaptchaData] = useState<CaptchaData | null>(null);
    const [v3SiteKey, setV3SiteKey] = useState<string | null>(null);
    const [confirmCodeError, setConfirmCodeError] = useState<string | undefined>(undefined);
    const [otpData, setOtpData] = useState<VerificationCodeData | null>(null);

    const verificationChannelUsedBeforeLastFail = useRef<FopVerificationChannel | null>(null);
    const {fetchUseFleetOwnerForSignup} = useFleetOwnerForSignup();
    const {handleUseFleetOwnerForSignupResponse} = useFleetOwnerForSignupResponse();
    const {
        get2faNavigationTo,
        get2faConfirmationNavigationTo,
        getContinueToCompanRegistrationStepsNavigationUrl,
        getContinueToCompanRegistrationOnboardingNavigationUrl,
        getContinueToAddCompanyNavigationUrl,
    } = useVerificationNavigations();

    const params = useParams();
    const attemptKey = params.key;

    const navigate = useNavigate();

    const handleOtpRequestError = useCallback(
        (error: unknown, channel: FopVerificationChannel) => {
            if (isCaptchaError(error)) {
                setCaptchaData({
                    type: error.response.error_data.data.challenge.type,
                    siteKey: error.response.error_data.data.challenge.parameters.site_key,
                });
                if (error.response.error_data.data.challenge.type === CaptchaChallengeType.RECAPTCHA_NATIVE) {
                    setV3SiteKey(error.response.error_data.data.challenge.parameters.site_key);
                }
            } else {
                // Error from useFleetOwnerForSignup
                if (isServerError(error) && error.response.code === ResponseCodes.TWO_FACTOR_AUTH_LOGIN_REQUIRED) {
                    navigate(get2faNavigationTo(attemptKey ?? ""), {replace: true});
                    return;
                }
                let errorMsg = i18n("auth.app.error-view.something-went-wrong.title");
                if (isServerErrorWithData(error)) {
                    if (error.response.error_data.text) {
                        errorMsg = error.response.error_data.text;
                    }
                }
                setRequestCodeError(errorMsg);
            }
            verificationChannelUsedBeforeLastFail.current = channel;
        },
        [attemptKey, get2faNavigationTo, i18n, navigate],
    );

    const handleOtpRequestAndConfirmSuccess = useCallback(
        (response: RequestOtpResponse, showToast?: boolean): {wasNavigated: boolean} => {
            const {type} = response;
            switch (type) {
                case VerificationChallengeSuccessType.OTP_REQUESTED:
                    setOtpData(response.otp_code);
                    if (showToast) {
                        if (response.otp_code.verification_code_channel === VerificationChannel.SMS) {
                            snackbar.add({description: i18n("login.phone.newCodeSent")}, {timeout: 2000});
                        } else if (response.otp_code.verification_code_channel === VerificationChannel.VOICE) {
                            snackbar.add({description: i18n("login.phone.callbackRequested")}, {timeout: 2000});
                        } else if (response.otp_code.verification_code_channel === VerificationChannel.WHATSAPP) {
                            snackbar.add({description: i18n("login.phone.newCodeSentWhatsApp")}, {timeout: 2000});
                        }
                    }
                    verificationChannelUsedBeforeLastFail.current = null;
                    return {wasNavigated: false};
                case VerificationChallengeSuccessType.CONTINUE_SIGNUP:
                    window.location.replace(`${window.origin}/signup/${response.signup_hash}?lang=${intl.locale}`);
                    break;
                case VerificationChallengeSuccessType.TWO_FACTOR_AUTH_REQUIRED:
                    navigate(get2faNavigationTo(attemptKey ?? ""), {replace: true});
                    break;
                case VerificationChallengeSuccessType.TWO_FACTOR_CONFIRMATION_REQUIRED:
                    navigate(get2faConfirmationNavigationTo(attemptKey ?? ""), {replace: true});
                    break;
                case VerificationChallengeSuccessType.USE_SIGNUP:
                    window.location.replace(config.landingPageUrl);
                    break;
                case VerificationChallengeSuccessType.CONTINUE_AUTH:
                    localStorageService.setLegacyItem(RefreshToken, response.refresh_token);
                    window.location.replace(`${window.origin}/v2`);
                    break;
                case VerificationChallengeSuccessType.USE_AUTH:
                    window.location.replace(`${window.origin}/v2`);
                    break;
                case VerificationChallengeSuccessType.CONTINUE_COMPANY_REGISTRATION_STEPS:
                    localStorageService.setLegacyItem(RefreshToken, response.refresh_token);
                    window.location.replace(
                        getContinueToCompanRegistrationStepsNavigationUrl(response.company_registration_hash),
                    );
                    break;
                case VerificationChallengeSuccessType.CONTINUE_COMPANY_REGISTRATION_ONBOARDING_PAGE:
                    localStorageService.setLegacyItem(RefreshToken, response.refresh_token);
                    window.location.replace(
                        getContinueToCompanRegistrationOnboardingNavigationUrl(response.company_id),
                    );
                    break;
                case VerificationChallengeSuccessType.ADD_NEW_COMPANY_REGISTRATION:
                    localStorageService.setLegacyItem(RefreshToken, response.refresh_token);
                    window.location.replace(getContinueToAddCompanyNavigationUrl());
                    break;
                default:
                    UnreachableCode.never(type);
                    setRequestCodeError(i18n("auth.app.error-view.something-went-wrong.title"));
                    return {wasNavigated: false};
            }
            return {wasNavigated: true};
        },
        [
            attemptKey,
            get2faConfirmationNavigationTo,
            get2faNavigationTo,
            getContinueToAddCompanyNavigationUrl,
            getContinueToCompanRegistrationOnboardingNavigationUrl,
            getContinueToCompanRegistrationStepsNavigationUrl,
            i18n,
            intl.locale,
            navigate,
            snackbar,
        ],
    );

    const requestCode = useCallback(
        async (data: RegularVerificationChallenge | CaptchaVerificationChallenge) => {
            if (!attemptKey || attemptKey.length < VerificationKeyMinLength) {
                return;
            }
            setCaptchaData(null);
            setRequestCodeError(undefined);
            setOtpData(null);
            setIsLoading(true);
            setConfirmCodeError(undefined);
            let channel = data.channel ?? VerificationChannel.SMS;
            if (verificationChannelUsedBeforeLastFail.current) {
                channel = verificationChannelUsedBeforeLastFail.current;
            }
            const deviceInfo = browserInfo.getDeviceInfo();
            const requestBody: FleetOwnerVerificationNoAuthService.FopVerificationChallengeRequest = {
                attempt_key: attemptKey,
                verification_code_channel: channel,
                device_id: deviceInfo.device_uuid,
                device_name: deviceInfo.user_agent,
                device_os_version: deviceInfo.device_os_version,
            };
            if (data.challenge) {
                requestBody.challenge = data.challenge;
            }
            try {
                const res = await FleetOwnerVerificationNoAuthApiClient.verificationChallenge(requestBody);
                const {wasNavigated} = handleOtpRequestAndConfirmSuccess(res, true);
                if (!wasNavigated) {
                    setIsLoading(false);
                }
            } catch (e: unknown) {
                handleOtpRequestError(e, channel);
                setIsLoading(false);
            }
        },
        [attemptKey, handleOtpRequestAndConfirmSuccess, handleOtpRequestError],
    );

    const onPageLoad = useCallback(async () => {
        if (!attemptKey || attemptKey.length < VerificationKeyMinLength) {
            return;
        }
        const deviceInfo = browserInfo.getDeviceInfo();
        const requestBody: FleetOwnerVerificationNoAuthService.StartVerificationRequest = {
            attempt_key: attemptKey,
            device_id: deviceInfo.device_uuid,
            device_name: deviceInfo.user_agent,
            device_os_version: deviceInfo.device_os_version,
        };
        try {
            const fleetOwnerForSignup = await fetchUseFleetOwnerForSignup({
                type: FleetOwnerVerificationService.UseFleetOwnerForSignupRequestType.USE_FLEET_OWNER_CHECK,
                attempt_key: attemptKey,
            });
            if (fleetOwnerForSignup) {
                const result = handleUseFleetOwnerForSignupResponse(fleetOwnerForSignup, true);
                if (result.type === "success") {
                    return;
                }
            }

            const res = await FleetOwnerVerificationNoAuthApiClient.startVerification(requestBody);
            const {wasNavigated} = handleOtpRequestAndConfirmSuccess(res);
            if (!wasNavigated) {
                setIsLoading(false);
            }
        } catch (e: unknown) {
            handleOtpRequestError(e, VerificationChannel.SMS);
            setIsLoading(false);
        }
    }, [
        attemptKey,
        fetchUseFleetOwnerForSignup,
        handleOtpRequestAndConfirmSuccess,
        handleOtpRequestError,
        handleUseFleetOwnerForSignupResponse,
    ]);

    const confirmCode = useCallback(
        async (otpCode: string) => {
            if (!attemptKey || !otpData) {
                return;
            }
            setIsLoading(true);
            setConfirmCodeError(undefined);
            const deviceInfo = browserInfo.getDeviceInfo();
            const requestBody: FleetOwnerVerificationNoAuthService.FopVerificationChallengeRequest = {
                attempt_key: attemptKey,
                verification_code_channel: otpData.verification_code_channel,
                device_id: deviceInfo.device_uuid,
                device_name: deviceInfo.user_agent,
                device_os_version: deviceInfo.device_os_version,
                otp_code: otpCode,
            };
            try {
                const res = await FleetOwnerVerificationNoAuthApiClient.verificationChallenge(requestBody);
                const {wasNavigated} = handleOtpRequestAndConfirmSuccess(res);
                if (!wasNavigated) {
                    setIsLoading(false);
                }
            } catch (e: unknown) {
                let errorMsg = i18n("auth.app.error-view.something-went-wrong.title");
                if (isServerErrorWithData(e)) {
                    if (e.response.error_data.text) {
                        errorMsg = e.response.error_data.text;
                    }
                }
                setConfirmCodeError(errorMsg);
                setIsLoading(false);
            }
        },
        [attemptKey, handleOtpRequestAndConfirmSuccess, i18n, otpData],
    );

    let Content = children;
    if (v3SiteKey) {
        Content = (
            <GoogleReCaptchaProvider reCaptchaKey={v3SiteKey} language={intl.locale}>
                {children}
            </GoogleReCaptchaProvider>
        );
    }
    return (
        <VerificationContextProvider.Provider
            value={{
                otpData,
                confirmCodeError,
                isLoading,
                requestCodeError,
                captchaData,
                setConfirmCodeError,
                setRequestCodeError,
                onPageLoad,
                requestCode,
                confirmCode,
            }}
        >
            {Content}
        </VerificationContextProvider.Provider>
    );
};

export {VerificationProvider, VerificationContextProvider};
