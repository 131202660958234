import {Location, matchPath} from "react-router-dom";

import {PageName} from "common/constants/pages";
import {
    CommonRoute,
    CompanyOnboardingRoutePrefix,
    CompanyRegistrationPrefix,
    FleetRoute,
    HoldingFleetRoutePrefix,
    ResetPasswordPrefix,
    VerificationPrefix,
} from "common/constants/routes";
import {NestedRoute, nestedRoutes} from "common/util/nestedRouteUtil";
import queryString from "query-string";

export const getRouteEndingPath = (route: string) => {
    return route.split("/").pop() || "";
};

export function getPageNameFromRoute(locationPathName: string) {
    const routeName = getRouteEndingPath(locationPathName);
    if (locationPathName.includes(HoldingFleetRoutePrefix)) {
        return getPageNameForHoldingFleetRoute(routeName);
    }
    if (locationPathName.includes(ResetPasswordPrefix)) {
        return PageName.RESET_PASSWORD;
    }
    if (locationPathName.includes(VerificationPrefix)) {
        return getPageNameForVerificationRoutes(locationPathName);
    }
    if (locationPathName.includes(CompanyRegistrationPrefix)) {
        return PageName.REGISTER_COMPANY;
    }
    return getPageNameForFleetRoute(routeName, locationPathName);
}

function getPageNameForFleetRoute(routeName: string, locationPathName: string) {
    switch (routeName) {
        case getRouteEndingPath(CommonRoute.AUTH):
            return PageName.LOGIN;
        case getRouteEndingPath(CommonRoute.FORGOT_PASSWORD):
            return PageName.FORGOT_PASSWORD;
        case FleetRoute.LIVE_MAP:
            return PageName.LIVE_MAP;
        case FleetRoute.EXPIRING_DOCUMENTS:
            return PageName.EXPIRING_DOCUMENTS;
        case FleetRoute.DRIVERS:
            return PageName.DRIVERS;
        case FleetRoute.CAR_RENTAL_PAYMENT:
            return PageName.CAR_RENTAL_PAYMENT;
        case FleetRoute.REPORTS:
            return PageName.REPORTS;
        case FleetRoute.COMPANY_SETTINGS:
            return PageName.COMPANY_SETTINGS;
        case FleetRoute.VEHICLES:
            return PageName.VEHICLES;
        case FleetRoute.VEHICLE_APPLICATION:
            return PageName.VEHICLE_APPLICATION;
        case CommonRoute.INVOICES:
            return PageName.INVOICES;
        case FleetRoute.ORDERS:
            return PageName.ORDERS;
        case CommonRoute.SETTINGS:
            return PageName.SETTINGS;
        case FleetRoute.CAMPAIGNS:
            return PageName.CAMPAIGNS;
        case FleetRoute.BALANCE:
            return PageName.BALANCE;
        case FleetRoute.NOTIFICATIONS:
            return PageName.NOTIFICATIONS;
        case FleetRoute.SHIFTS:
            return PageName.SHIFTS;
        case FleetRoute.VEHICLE_LISTING_CREATE:
            return PageName.VEHICLE_LISTING;
        default: {
            const nestedRoute = nestedRoutes.find((path) => matchPath({path}, locationPathName));
            if (nestedRoute === NestedRoute.DRIVER_DETAILS) {
                return PageName.DRIVER;
            }
            if (nestedRoute === NestedRoute.VEHICLE_DETAILS) {
                return PageName.VEHICLE;
            }
            if (nestedRoute === NestedRoute.DRIVER_REGISTRATION_DETAILS) {
                return PageName.DRIVER_REGISTRATION;
            }
            if (nestedRoute === NestedRoute.DRIVER_INVITATION_DETAILS) {
                return PageName.DRIVER_INVITATION;
            }
            if (nestedRoute === NestedRoute.CAMPAIGN_DETAILS) {
                return PageName.CAMPAIGN_DETAILS;
            }
            if (nestedRoute === NestedRoute.VEHICLE_LISTING_DETAILS) {
                return PageName.VEHICLE_LISTING;
            }
            return null;
        }
    }
}

function getPageNameForHoldingFleetRoute(routeName: string) {
    switch (routeName) {
        case CommonRoute.INVOICES:
            return PageName.ENTERPRISE_INVOICES;
        case CommonRoute.SETTINGS:
            return PageName.SETTINGS;
        default: {
            return null;
        }
    }
}

function getPageNameForVerificationRoutes(routeName: string) {
    const paths = [
        CommonRoute.VERIFICATION,
        CommonRoute.VERIFICATION_PASSWORD,
        CommonRoute.VERIFICATION_CONFIRMATION,
        CommonRoute.VERIFICATION_2FA,
        CommonRoute.VERIFICATION_2FA_CONFIRMATION,
    ];
    const matchedRoute = paths.find((path) => matchPath({path}, routeName));
    switch (matchedRoute) {
        case CommonRoute.VERIFICATION:
            return PageName.VERIFICATION;
        case CommonRoute.VERIFICATION_PASSWORD:
            return PageName.VERIFICATION_PASSWORD;
        case CommonRoute.VERIFICATION_CONFIRMATION:
            return PageName.VERIFICATION_CONFIRMATION;
        case CommonRoute.VERIFICATION_2FA:
            return PageName.VERIFICATION_2FA;
        case CommonRoute.VERIFICATION_2FA_CONFIRMATION:
            return PageName.VERIFICATION_2FA_CONFIRMATION;
        default:
            return null;
    }
}

export const getReturnPath = (location: Location): string | null => {
    const query = queryString.parse(location.search);
    return query.to as string | null;
};

export const createReturnUrl = (to: string): string => {
    return queryString.stringify({to});
};

export function checkIfHoldingFleetRoute(locationPathName: string) {
    return locationPathName.includes(HoldingFleetRoutePrefix);
}

export function checkIfCompanyOnboardingRoute(locationPathName: string) {
    return locationPathName.includes(CompanyOnboardingRoutePrefix);
}

export function checkIfAddCompanyRoute(locationPathName: string) {
    return locationPathName.includes(CompanyRegistrationPrefix);
}
