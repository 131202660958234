import {useCallback} from "react";

import {FormValidatorResult, ValidatorResult} from "common/constants/types";
import {useI18n} from "common/hooks/useI18n";
import {FormValidationError} from "common/util/errors/formValidationError";
import {ValidationError} from "common/util/errors/validationError";
import {TranslationKeys} from "config/translations";

interface ValidatorMessage {
    property?: string | number;
    message: string;
}

export const useValidationErrorMessages = () => {
    const {i18n} = useI18n();

    const getMessage = useCallback(
        (message: ValidatorMessage) => {
            if (message.property) {
                return i18n(
                    `api.error.validation.${message.property}.${message.message}` as TranslationKeys,
                    undefined,
                    "api.default_error",
                );
            }
            return null;
        },
        [i18n],
    );

    const getValidationErrorMessages = useCallback(
        (error: ValidationError) => {
            return error.validatorResults.map((validatorResult: ValidatorResult) => {
                const validationMessage = {property: validatorResult.property, message: "invalid"};

                if (validatorResult.error.includes("required")) {
                    validationMessage.message = "required";
                }

                if (validationMessage.message !== "invalid" && !getMessage(validationMessage)) {
                    validationMessage.message = "invalid";
                }

                // Current workaround for phone
                if (validationMessage.property === "phone" && validationMessage.message === "invalid") {
                    return {
                        property: "phone",
                        error: getMessage({property: "phone_number", message: "invalid"}) || validatorResult.error,
                    };
                }

                return {
                    property: validatorResult.property,
                    error: getMessage(validationMessage) || validatorResult.error,
                };
            });
        },
        [getMessage],
    );

    const getCarFormValidationErrorMessages = useCallback(
        (error: FormValidationError) => {
            return error.errors.map((validatorResult: FormValidatorResult) => {
                const validationMessage = {property: validatorResult.attribute, message: "invalid"};

                if (validatorResult.message.includes("required")) {
                    validationMessage.message = "required";
                }

                if (validationMessage.message !== "invalid" && !getMessage(validationMessage)) {
                    validationMessage.message = "invalid";
                }

                return {
                    property: validatorResult.attribute,
                    error: getMessage(validationMessage) || validatorResult.message,
                };
            });
        },
        [getMessage],
    );

    const getDynamicFormValidationErrorMessages = useCallback((error: FormValidationError) => {
        return error.errors.map((validatorResult: FormValidatorResult) => {
            return {
                property: validatorResult.attribute,
                error: validatorResult.message,
            };
        });
    }, []);

    return {getValidationErrorMessages, getCarFormValidationErrorMessages, getDynamicFormValidationErrorMessages};
};
