import {useContext} from "react";

import Header, {HeaderSize} from "common/components/header/Header";

import imgUrl from "../../assets/car.png";
import {ListingContextProvider} from "./ListingInfoProvider";

const WizardSummary = () => {
    const {listing} = useContext(ListingContextProvider);
    const title = listing
        ? `${listing.data.vehicle_manufacturer} ${listing.data.vehicle_model_name} (${listing.data.vehicle_years?.join(
              ", ",
          )})`
        : "";

    return (
        <div>
            <div>
                <img src={imgUrl} alt="Vehicle" className="object-fit h-48" />
            </div>
            <Header size={HeaderSize.Small} text={title} />
        </div>
    );
};

export default WizardSummary;
