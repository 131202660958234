import {FC, useCallback, useContext, useEffect, useMemo, useState} from "react";
import {useIntl} from "react-intl";
import {generatePath, useSearchParams} from "react-router-dom";

import ErrorView, {ErrorViewType} from "common/components/error/ErrorView";
import {NotificationType} from "common/components/notifications/TopLeftCorner";
import {Page} from "common/components/page/Page";
import Pagination from "common/components/Pagination";
import AccordionTableWithCustomizableColumns from "common/components/table/AccordionTableWithCustomizableColumns";
import TableCustomizationDrawer from "common/components/table/TableCustomizationDrawer";
import TableWithCustomizableColumns from "common/components/table/TableWithCustomizableColumns";
import {Dash} from "common/constants";
import {FleetRoute} from "common/constants/routes";
import {CountryCode} from "common/geo/constants/countries";
import {FetchStatus, useFetch} from "common/hooks/useFetch";
import {useI18n} from "common/hooks/useI18n";
import {useTailwindViewport} from "common/hooks/useTailwindViewport";
import {NotificationContext} from "common/services/notificationProvider";
import {ApiPeriod, apiPeriodToDates, OngoingDay, Period} from "common/services/period";
import {TABLE_FIXED_COLUMN_CLASSNAME} from "common/styles";
import {getDocumentTitle} from "common/util/DocumentTitleUtil";
import {formatNumber} from "common/util/FormatUtil";
import {AccountContextProvider} from "features/account/accountStateProvider";
import {FeaturesContextProvider} from "FeaturesProvider";

import {DriverEarningsService} from "@bolteu/bolt-server-api-fleet-owner-portal";
import {RowActionContext} from "@bolteu/kalep-table-react";

import TableSearchField from "../../../common/components/table/TableSearchField";
import DownloadEarnings from "./components/DownloadEarnings";
import {EarningsCardsRow} from "./components/EarningsCardsRow";
import ReportsPageHeader from "./components/ReportsPageHeader";
import {useAccordionTableColumns} from "./hooks/useAccordionTableColumns";
import {useDriverEarningsApi} from "./hooks/useDriverEarningsApi";
import {useTableColumns} from "./hooks/useTableColumns";

const ROWS_IN_TABLE = 50;

const TABLE_ID = "driver-earnings-table";

export type ReportsTableData = Omit<Record<keyof DriverEarningsService.DriverData, string>, "expense_booking_fees">;

const ReportsPage: FC = () => {
    const intl = useIntl();
    const {i18n} = useI18n();
    const [pageNum, setPageNum] = useState(0);
    const [selectedPeriod, setSelectedPeriod] = useState<ApiPeriod>({period: Period.ONGOING_DAY} as OngoingDay);
    const [tableSearchValue, setTableSearchValue] = useState("");
    const {setNotification} = useContext(NotificationContext);
    const featuresContext = useContext(FeaturesContextProvider);
    const driverEarningsApi = useDriverEarningsApi(selectedPeriod);
    const {data, status, fetch} = useFetch(driverEarningsApi);

    const fleetCountry = useContext(AccountContextProvider)?.getFleet()?.country;

    const totalRows = data ? data.total_rows : 0;
    const currency = data ? data.currency_symbol : "";

    const viewport = useTailwindViewport();
    const isMobileView = viewport === "sm";
    const columns = useTableColumns(currency, isMobileView, data?.columns_configuration);
    const accordionTableColumns = useAccordionTableColumns(currency, data?.columns_configuration);

    const goToDriverProfile = useCallback(
        (id: string) => () => {
            window.open(generatePath(FleetRoute.DRIVER, {id}));
        },
        [],
    );

    const getItemHeader = useCallback(
        (item: ReportsTableData) => {
            const isMissingData =
                item.gross_revenue === Dash.HYPHEN || item.gross_revenue === Dash.EN || item.gross_revenue === Dash.EM;
            const currencySymbol = !isMissingData && currency ? currency : "";

            return {
                primary: item.name,
                secondary: `${columns.gross_revenue?.label}: ${item.gross_revenue}${currencySymbol}`,
            };
        },
        [columns.gross_revenue?.label, currency],
    );

    const inlineActions = useMemo(() => {
        return [
            {
                label: i18n("auth.app.fleet.reports.open_profile"),
                customInlineRenderer: (context: RowActionContext<ReportsTableData>) => {
                    const {id} = context.row;

                    return (
                        <button type="button" onClick={goToDriverProfile(id)}>
                            <div className="text-action-primary">{i18n("auth.app.fleet.reports.open_profile")}</div>
                        </button>
                    );
                },
            },
        ];
    }, [i18n, goToDriverProfile]);

    useEffect(() => {
        const pageTitle = i18n("auth.app.fleet.reports.title_earnings");
        document.title = getDocumentTitle(pageTitle);
    }, [i18n]);

    const tableData = useMemo((): ReportsTableData[] => {
        if (!data) {
            return [];
        }
        return data.drivers.map((d) => {
            return {
                id: String(d.id),
                uuid: String(d.uuid),
                phone: String(d.phone),
                email: String(d.email),
                partner_uuid: String(d.partner_uuid || ""),
                name: d.name,
                earnings_per_hour_gross: formatNumber(intl, d.earnings_per_hour_gross),
                earnings_per_hour_net: formatNumber(intl, d.earnings_per_hour_net),
                net_earnings: formatNumber(intl, d.net_earnings),
                gross_revenue: formatNumber(intl, d.gross_revenue),
                gross_revenue_app: formatNumber(intl, d.gross_revenue_app),
                gross_revenue_cash: formatNumber(intl, d.gross_revenue_cash),
                cash_in_hand: formatNumber(intl, d.cash_in_hand),
                payouts: formatNumber(intl, d.payouts),
                tips: formatNumber(intl, d.tips),
                bonuses: formatNumber(intl, d.bonuses),
                compensations: formatNumber(intl, d.compensations),
                cancellation_fees: formatNumber(intl, d.cancellation_fees),
                booking_fees: formatNumber(intl, d.booking_fees),
                toll_roads: formatNumber(intl, d.toll_roads),
                expense_refunds: formatNumber(intl, d.expense_refunds),
                expenses_total: formatNumber(intl, d.expenses_total),
                expense_commissions: formatNumber(intl, d.expense_commissions),
                expense_other_fees: formatNumber(intl, d.expense_other_fees),
                withholding_tax: formatNumber(intl, d.withholding_tax),
                vat_sum_bonuses: formatNumber(intl, d.vat_sum_bonuses),
                vat_sum_booking_fees: formatNumber(intl, d.vat_sum_booking_fees),
                vat_sum_compensations: formatNumber(intl, d.vat_sum_compensations),
                vat_sum_revenue_cash: formatNumber(intl, d.vat_sum_revenue_cash),
                vat_sum_revenue_in_app: formatNumber(intl, d.vat_sum_revenue_in_app),
                vat_sum_cancellation_fees: formatNumber(intl, d.vat_sum_cancellation_fees),
            };
        });
    }, [data, intl]);

    const getReportsData = useCallback(
        (newPageNum: number) => {
            if (fetch && featuresContext?.earning_reports) {
                const offset = newPageNum * ROWS_IN_TABLE;
                fetch({offset, limit: ROWS_IN_TABLE, search: tableSearchValue});
            }
        },
        [featuresContext?.earning_reports, fetch, tableSearchValue],
    );

    useEffect(() => {
        setPageNum(0);
        getReportsData(0);
    }, [getReportsData]);

    const onPageChange = useCallback(
        async (page: number) => {
            getReportsData(page);
            setPageNum(page);
        },
        [getReportsData],
    );

    const onPeriodChange = useCallback(
        (newPeriod: ApiPeriod) => {
            const dates = apiPeriodToDates(newPeriod);
            // Temporary solution to disable data aggregation across year change in Croatia
            if (
                fleetCountry === CountryCode.Croatia &&
                dates.start.getFullYear().toString() === "2022" &&
                dates.end.getFullYear().toString() === "2023"
            ) {
                setNotification({
                    type: NotificationType.ERROR,
                    text: i18n("auth.app.fleet.reports.date_picker.unable_to_display_data_date_range_error"),
                    timeout: 6000,
                });
                setSelectedPeriod({period: Period.ONGOING_DAY});
            } else {
                setSelectedPeriod(newPeriod);
            }
        },
        [fleetCountry, i18n, setNotification],
    );

    const onSearchChange = useCallback((search: string) => {
        setTableSearchValue(search);
    }, []);

    const [searchParams] = useSearchParams();
    const isFixedColumnEnabled = searchParams.get("fixedColumn") === "true";
    const overrideClassName = isFixedColumnEnabled ? TABLE_FIXED_COLUMN_CLASSNAME : "";

    if (!featuresContext?.earning_reports) {
        return <ErrorView type={ErrorViewType.ServiceUnavailable} />;
    }

    if (status === FetchStatus.Error) {
        return <ErrorView type={ErrorViewType.SomethingWentWrong} />;
    }

    return (
        <Page>
            <ReportsPageHeader period={selectedPeriod} onPeriodChange={onPeriodChange} />
            <EarningsCardsRow period={selectedPeriod} />
            <div className="mt-2 flex w-full flex-col gap-4 sm:flex-row sm:items-center sm:justify-between">
                <div className="flex flex-[2] gap-4">
                    <TableSearchField onChange={onSearchChange} />
                    <TableCustomizationDrawer tableId={TABLE_ID} columns={columns} />
                </div>
                <div className="sm:flex sm:justify-end">
                    <DownloadEarnings period={selectedPeriod} />
                </div>
            </div>
            {isMobileView ? (
                <AccordionTableWithCustomizableColumns
                    getItemHeader={getItemHeader}
                    columns={accordionTableColumns}
                    items={tableData}
                    inlineActions={inlineActions}
                    aria-label="Driver earnings"
                    isLoading={[FetchStatus.Loading, FetchStatus.Init].includes(status)}
                    loadingSkeletonRowCount={5}
                    paddingStart={0}
                    paddingEnd={0}
                    id={TABLE_ID}
                />
            ) : (
                <TableWithCustomizableColumns
                    columns={columns}
                    items={tableData}
                    aria-label="Driver earnings"
                    isLoading={[FetchStatus.Loading, FetchStatus.Init].includes(status)}
                    loadingSkeletonRowCount={5}
                    overrideClassName={overrideClassName}
                    id={TABLE_ID}
                />
            )}
            <Pagination
                currentPage={pageNum}
                pageSize={ROWS_IN_TABLE}
                totalCount={totalRows}
                onPageChange={onPageChange}
                disabled={[FetchStatus.Init, FetchStatus.Loading].includes(status)}
                showText
            />
        </Page>
    );
};

export default ReportsPage;
