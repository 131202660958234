import {lazy, Suspense, useContext} from "react";
import {Navigate, Route, Routes, useLocation} from "react-router-dom";

import {FullScreenLoadingSpinner} from "common/components/spinner";
import {
    CommonRoute,
    CompanyOnboardingRoutePrefix,
    DeepLinkPrefix,
    FleetRoute,
    HoldingFleetRoutePrefix,
} from "common/constants/routes";
import {useCompanyData} from "common/hooks/useCompanyData";
import BaseLayout from "common/layouts/BaseLayout";
import ChooseCompanyLayout from "common/layouts/ChooseCompanyLayout";
import CompanyManagementLayout from "common/layouts/CompanyManagementLayout";
import {HasLoggedIn} from "common/services/authService";
import {localStorageService} from "common/services/LocalStorageService";
import {createReturnUrl, getReturnPath} from "common/util/routeUtils";
import config from "config/index";
import {AccountContextProvider} from "features/account/accountStateProvider";
import CompanyOnboarding from "features/account/components/CompanyOnboarding";
import CompanySettingsPage from "features/account/pages/CompanySettings";
import ForgotPassword from "features/account/pages/ForgotPassword";
import LoginPage from "features/account/pages/Login";
import VerificationPage from "features/account/pages/Verification";
import VerificationAuthOutlet from "features/account/pages/Verification/VerificationAuthOutlet";
import VerificationConfirmation from "features/account/pages/VerificationConfirmation";
import VerificationPassword from "features/account/pages/VerificationPassword";
import VerificationTwoFactorAuthPage from "features/account/pages/VerificationTwoFactorAuth";
import VerificationTwoFactorAuthConfirmationPage from "features/account/pages/VerificationTwoFactorAuthConfirmation";
import {ApplicationContextProvider} from "features/app/appStateProvider";
import SmsNotificationRedirect from "features/app/notifications/SmsNotificationRedirect";
import BalancePage from "features/companies/balance";
import CampaignDetails from "features/companies/campaigns/details";
import CampaignsPage from "features/companies/campaigns/list";
import CarRentalsPage from "features/companies/car-rental-payment";
import DriversListPage from "features/companies/drivers/list-view";
import DriverProfile from "features/companies/drivers/profile/components/DriverProfile";
import DriverRegistration from "features/companies/drivers/registration/components/DriverRegistration";
import ExpiringDocumentsPage from "features/companies/expiring-documents";
import InvoicesPage from "features/companies/invoices";
import LiveMapPage from "features/companies/live-map/components";
import OrdersPage from "features/companies/orders";
import ReportsPage from "features/companies/reports";
import ShiftsPage from "features/companies/shifts";
import EditVehicleListingWizardPage from "features/companies/vehicleListings/add-listing/EditVehicleListingWizardPage";
import VehiclesPage from "features/companies/vehicles/list-view";
import VehicleDetailsPage from "features/companies/vehicles/profile";
import HoldingFleetInvoices from "features/holding-fleets/invoices";

import ResetPassword from "./features/account/pages/ResetPassword";
import SettingsPage from "./features/account/pages/Settings";
import NotificationFullView from "./features/app/notifications/NotificationFullView";
import SupportRedirect from "./features/app/support/SupportRedirect";
import DriverInvitation from "./features/companies/drivers/invitation/components/DriverInvitation";
import AddVehicleListingPage from "./features/companies/vehicleListings/add-listing/AddVehicleListingPage";
import VehicleApplicationDetailsPage from "./features/companies/vehicles/application";

export const fleetIndexRoute = FleetRoute.DRIVERS;
export const holdingFleetIndexRoute = CommonRoute.INVOICES;

const CompanyAdd = lazy(() => import("features/account/pages/Company/CompanyAdd"));
const CompanyRegistration = lazy(() => import("features/account/pages/Company/CompanyRegistration"));

export const PrivateRoutes = () => {
    const accountState = useContext(AccountContextProvider);
    const appState = useContext(ApplicationContextProvider);
    const {isOnlyCompanyRegistrations} = useCompanyData();

    if (!appState.isAuthenticated) {
        return <></>;
    }

    const Redirect = () => {
        const location = useLocation();
        const returnUrl = getReturnPath(location);
        return <Navigate to={String(returnUrl || "")} />;
    };

    const selectedCompanyType = accountState.selectedCompany?.type;
    const selectedCompanyId = `${accountState.selectedCompany?.company?.id}-${selectedCompanyType}`;

    const AddCompanyRouteRootElement = isOnlyCompanyRegistrations ? (
        <BaseLayout navigationLinks={[]} topbarVariant="NON_ACCOUNT" />
    ) : undefined;

    return (
        <Suspense fallback={<FullScreenLoadingSpinner />}>
            <Routes key={selectedCompanyId}>
                <Route path="" element={<ChooseCompanyLayout />} />
                <Route path={CommonRoute.ADD_COMPANY} element={AddCompanyRouteRootElement}>
                    <Route index element={<CompanyAdd />} />
                    <Route path=":hash" element={<CompanyRegistration />} />
                </Route>
                <Route path=":fleetId" element={<CompanyManagementLayout variant="FLEET" />}>
                    <Route index element={<Navigate to={fleetIndexRoute} />} />
                    <Route path={FleetRoute.LIVE_MAP} element={<LiveMapPage />} />
                    <Route path={FleetRoute.EXPIRING_DOCUMENTS} element={<ExpiringDocumentsPage />} />
                    <Route path={FleetRoute.DRIVERS}>
                        <Route index element={<DriversListPage />} />
                        <Route path=":id" element={<DriverProfile />} />
                    </Route>
                    <Route path={FleetRoute.DRIVER_REGISTRATION} element={<DriverRegistration />} />
                    <Route path={FleetRoute.DRIVER_INVITATION} element={<DriverInvitation />} />
                    <Route path={FleetRoute.CAR_RENTAL_PAYMENT} element={<CarRentalsPage />} />
                    <Route path={FleetRoute.REPORTS} element={<ReportsPage />} />
                    <Route path={FleetRoute.COMPANY_SETTINGS} element={<CompanySettingsPage />} />
                    <Route path={FleetRoute.VEHICLES}>
                        <Route index element={<VehiclesPage />} />
                        <Route path=":id" element={<VehicleDetailsPage />} />
                    </Route>
                    <Route path={FleetRoute.VEHICLE_APPLICATION} element={<VehicleApplicationDetailsPage />} />
                    <Route path={CommonRoute.INVOICES} element={<InvoicesPage />} />
                    <Route path={FleetRoute.ORDERS} element={<OrdersPage />} />
                    <Route path={CommonRoute.SETTINGS} element={<SettingsPage />} />
                    <Route path={FleetRoute.CAMPAIGNS} element={<CampaignsPage />} />
                    <Route path={FleetRoute.CAMPAIGN_DETAILS} element={<CampaignDetails />} />
                    <Route path={FleetRoute.BALANCE} element={<BalancePage />} />
                    <Route path={FleetRoute.NOTIFICATIONS} element={<NotificationFullView />} />
                    <Route path={FleetRoute.SHIFTS} element={<ShiftsPage />} />
                    <Route path={FleetRoute.VEHICLE_LISTING_CREATE}>
                        <Route index element={<AddVehicleListingPage />} />
                        <Route path=":id" element={<EditVehicleListingWizardPage />} />
                    </Route>
                    <Route path={DeepLinkPrefix + FleetRoute.SUPPORT} element={<SupportRedirect />} />
                </Route>
                <Route
                    path={`${HoldingFleetRoutePrefix}/:holdingFleetId`}
                    element={<CompanyManagementLayout variant="HOLDING_FLEET" />}
                >
                    <Route index element={<Navigate to={holdingFleetIndexRoute} />} />
                    <Route path={CommonRoute.INVOICES} element={<HoldingFleetInvoices />} />
                    <Route path={CommonRoute.SETTINGS} element={<SettingsPage />} />
                </Route>
                <Route
                    path={`${CompanyOnboardingRoutePrefix}/:companyId`}
                    element={<CompanyManagementLayout variant="INACTIVE_COMPANY" />}
                >
                    <Route index element={<CompanyOnboarding />} />
                    <Route path={FleetRoute.DRIVERS} element={<DriversListPage />} />
                    <Route path={FleetRoute.DRIVER_REGISTRATION} element={<DriverRegistration />} />
                    <Route path={FleetRoute.DRIVER_INVITATION} element={<DriverInvitation />} />
                    <Route path={FleetRoute.VEHICLES} element={<VehiclesPage />} />
                    <Route path={FleetRoute.VEHICLE_APPLICATION} element={<VehicleApplicationDetailsPage />} />
                    <Route path={CommonRoute.SETTINGS} element={<SettingsPage />} />
                </Route>
                <Route path={CommonRoute.SMS_NOTIFICATION} element={<SmsNotificationRedirect />} />
                <Route path="*" element={<Redirect />} />
            </Routes>
        </Suspense>
    );
};

export const PublicRoutes = () => {
    const appState = useContext(ApplicationContextProvider);

    const Redirect = () => {
        const location = useLocation();
        let returnUrl = getReturnPath(location);
        const hasBeenLoggedIn = localStorageService.getLegacyItem(HasLoggedIn);

        if (!returnUrl && location.pathname && location.pathname !== "/") {
            returnUrl = location.pathname + location.search;
        } else if (!returnUrl && !appState.isAuthenticated && !hasBeenLoggedIn && !config.isEnvLocal) {
            window.location.href = config.landingPageUrl;
            return <></>;
        }

        const to = returnUrl || fleetIndexRoute;
        const query = createReturnUrl(to);
        return <Navigate to={`${CommonRoute.AUTH}?${query}`} />;
    };

    if (appState.isAuthenticated) {
        return <></>;
    }

    return (
        <Suspense fallback={<FullScreenLoadingSpinner />}>
            <Routes>
                <Route path={CommonRoute.AUTH} element={<LoginPage />} />
                <Route path={CommonRoute.FORGOT_PASSWORD} element={<ForgotPassword />} />
                <Route path={CommonRoute.RESET_PASSWORD} element={<ResetPassword />} />
                <Route element={<VerificationAuthOutlet />}>
                    <Route path={CommonRoute.VERIFICATION} element={<VerificationPage />} />
                    <Route path={CommonRoute.VERIFICATION_PASSWORD} element={<VerificationPassword />} />
                    <Route path={CommonRoute.VERIFICATION_CONFIRMATION} element={<VerificationConfirmation />} />
                    <Route path={CommonRoute.VERIFICATION_2FA} element={<VerificationTwoFactorAuthPage />} />
                    <Route
                        path={CommonRoute.VERIFICATION_2FA_CONFIRMATION}
                        element={<VerificationTwoFactorAuthConfirmationPage />}
                    />
                </Route>
                <Route path="*" element={<Redirect />} />
            </Routes>
        </Suspense>
    );
};
