import {useCallback} from "react";
import {useNavigate} from "react-router-dom";

import {EventName} from "common/constants/events";
import {CommonRoute, FleetRoute} from "common/constants/routes";
import {Fleet, HoldingFleet, isDraftFleet, isFleet} from "common/constants/types";
import {useI18n} from "common/hooks/useI18n";
import {useTracking} from "common/hooks/useTracking";
import {SelectedCompany} from "features/account/accountStateProvider";

import {Button, IconButton, ListItemLayout, Typography} from "@bolteu/kalep-react";
import {Settings} from "@bolteu/kalep-react-icons";

import {CompanyMenu} from "./CompanyMenu";
import {useCompanySelector} from "./hooks/useCompanySelector";

const CompanyDropdown = ({
    fleets,
    selectedCompany,
    holdingFleets,
}: {
    selectedCompany: SelectedCompany | null;
    fleets: Fleet[];
    holdingFleets: HoldingFleet[];
}) => {
    const {i18n} = useI18n();
    const navigate = useNavigate();
    const {trackEvent} = useTracking();
    const {getDescription, getStatusChip, getCompanySettingsShown, renderCompanySelectionItems, getCompanyName} =
        useCompanySelector();

    const selectedCompanyName = getCompanyName(selectedCompany?.company);
    const shouldShowCompanySettings = getCompanySettingsShown(selectedCompany?.company);
    const description = getDescription(selectedCompany?.company, false);

    const navigateToCompanySettings = useCallback(() => {
        navigate(FleetRoute.COMPANY_SETTINGS);
    }, [navigate]);

    const navigateToAddCompany = useCallback(() => {
        trackEvent(EventName.AddCompanyButtonClicked);
        navigate(CommonRoute.ADD_COMPANY);
    }, [navigate, trackEvent]);

    const renderCompanySettings = useCallback(() => {
        return (
            <IconButton
                icon={<Settings />}
                size="sm"
                variant="ghost"
                onClick={navigateToCompanySettings}
                aria-label="Go to company settings"
            />
        );
    }, [navigateToCompanySettings]);

    const renderStatusChip = useCallback(() => {
        if (selectedCompany && isFleet(selectedCompany?.company)) {
            return getStatusChip(selectedCompany?.company.status);
        }
        return null;
    }, [getStatusChip, selectedCompany]);

    const shouldHideAddCompanyButton = fleets.some((fleet) => isDraftFleet(fleet));

    return (
        <CompanyMenu selectedCompanyName={selectedCompanyName}>
            <div className="flex max-h-[400px] flex-col overflow-hidden">
                <div className="bg-neutral-secondary m-1 rounded-sm p-1">
                    <ListItemLayout
                        variant="base"
                        primary={
                            <Typography fontSize="text-lg" fontWeight="semibold" variant="body-primary">
                                {selectedCompanyName}
                            </Typography>
                        }
                        secondary={description}
                        renderEndSlot={shouldShowCompanySettings ? renderCompanySettings : renderStatusChip}
                    />
                </div>
                <div className="flex-1 overflow-auto px-2">
                    {renderCompanySelectionItems(selectedCompany, fleets, holdingFleets)}
                </div>
                {!shouldHideAddCompanyButton && (
                    <div className="p-6">
                        <Button variant="secondary" size="md" onClick={navigateToAddCompany}>
                            {i18n("company-selector.add_company")}
                        </Button>
                    </div>
                )}
            </div>
        </CompanyMenu>
    );
};

export {CompanyDropdown};
