import React, {useCallback, useState} from "react";

import {ResponsiveDrawer} from "common/components/drawer/ResponsiveDrawer";
import {useI18n} from "common/hooks/useI18n";
import {localStorageService} from "common/services/LocalStorageService";
import {useLocalStorage} from "@fleet/common/hooks/useLocalStorage";

import {FleetOwnerPortalService} from "@bolteu/bolt-server-api-fleet-owner-portal";
import {Checkbox, Drawer, IconButton} from "@bolteu/kalep-react";
import {Settings} from "@bolteu/kalep-react-icons";

import {TableColumnSettings} from "../TableWithCustomizableColumns";
import {useApiDrivenTableColumns} from "./Common";

interface ApiDrivenTablesCustomizationDrawerProps {
    tableId: string;
    columns: FleetOwnerPortalService.ApiColumn[];
}

interface CustomizableColumn {
    column: FleetOwnerPortalService.ApiColumn;
    visible: boolean;
}

function ApiDrivenTablesCustomizationDrawer(props: ApiDrivenTablesCustomizationDrawerProps) {
    const {i18n} = useI18n();

    const [value, setValue] = useLocalStorage<TableColumnSettings>(
        localStorageService.getTableSettingsKey(props.tableId),
        {},
    );
    const {customizableColumns} = useApiDrivenTableColumns(props.tableId, props.columns);

    const customColumns: CustomizableColumn[] = customizableColumns.map((column) => {
        const isShown: boolean = value[column.key] ?? true; // we assume that the column is shown by default
        return {column, visible: isShown};
    });

    function handleColumnVisibilityChange(columnKey: string, visible: boolean) {
        setValue({...value, [columnKey]: visible});
    }

    function onColumnCheckBoxChange(columnKey: string) {
        return (evt: React.ChangeEvent<HTMLInputElement>) => {
            handleColumnVisibilityChange(columnKey, evt.target.checked);
        };
    }

    const [isOpen, setIsOpen] = useState(false);

    const handleFiltersClose = useCallback(() => {
        setIsOpen(false);
    }, []);

    const handleFilterButtonClick = useCallback(() => {
        setIsOpen(true);
    }, []);

    return (
        <div>
            <IconButton
                overrideClassName="flex h-12 w-12"
                shape="square"
                size="lg"
                icon={<Settings />}
                onClick={handleFilterButtonClick}
                aria-label="Open settings"
                value="tableSettingIconBtn"
            />

            <ResponsiveDrawer isOpen={isOpen} title={i18n("common.settings")} onRequestClose={handleFiltersClose}>
                <Drawer.Content>
                    <div className="flex flex-col gap-4">
                        <h3 className="font-semibold">{i18n("common.show_columns")}</h3>
                        {customColumns.map((customizableColumn) => (
                            <div className="pt-3" key={customizableColumn.column.key}>
                                <Checkbox
                                    name={customizableColumn.column.key}
                                    label={customizableColumn.column.title}
                                    checked={customizableColumn.visible}
                                    onChange={onColumnCheckBoxChange(customizableColumn.column.key)}
                                />
                            </div>
                        ))}
                    </div>
                </Drawer.Content>
            </ResponsiveDrawer>
        </div>
    );
}

export default ApiDrivenTablesCustomizationDrawer;
