import {Checkbox} from "common/components/dynamicForm/Checkbox";
import {Combobox} from "common/components/dynamicForm/Combobox";
import {ComboboxTextValue} from "common/components/dynamicForm/ComboboxText";
import {Email} from "common/components/dynamicForm/Email";
import {getComboboxOptions} from "common/components/dynamicForm/hooks/useComboBox";
import {Number} from "common/components/dynamicForm/Number";
import {Password} from "common/components/dynamicForm/Password";
import {Radio} from "common/components/dynamicForm/Radio";
import {Text} from "common/components/dynamicForm/Text";
import {FormValueType, UseFormValueType} from "common/components/dynamicForm/types";
import {UnreachableCode} from "common/components/util/UnreachableCode";
import {ValidatorResult} from "common/constants/types";

import {FleetOwnerRegistrationService} from "@bolteu/bolt-server-api-fleet-owner-portal";
import {SelectOption} from "@bolteu/kalep-react";

import {CheckboxGroup} from "../CheckboxGroup";
import {InfoGroupText} from "../InfoGroupText";
import {InfoText} from "../InfoText";

const getApiToFormValues = (formFields: FleetOwnerRegistrationService.FormField[]): FormValueType => {
    return formFields.reduce((acc, field) => {
        const {name, value, type} = field;

        switch (type) {
            case FleetOwnerRegistrationService.FormFieldType.TEXT: {
                const {prefix_combo_box} = field;
                if (!prefix_combo_box) {
                    acc[name] = value;
                } else {
                    const {value: prefixValue, options} = prefix_combo_box;
                    const autocompleteFieldOptions = getComboboxOptions(options);
                    acc[name] = {
                        textValue: value,
                        comboboxValue: autocompleteFieldOptions.find((option) => option.value === prefixValue),
                    };
                }
                break;
            }
            case FleetOwnerRegistrationService.FormFieldType.COMBOBOX:
                if (field.multi) {
                    acc[name] = field.values;
                } else {
                    acc[name] = value;
                }
                break;
            case FleetOwnerRegistrationService.FormFieldType.CHECKBOXGROUP:
                acc[name] = field.values;
                break;
            case FleetOwnerRegistrationService.FormFieldType.RADIOGROUP:
            case FleetOwnerRegistrationService.FormFieldType.PASSWORD:
            case FleetOwnerRegistrationService.FormFieldType.EMAIL:
            case FleetOwnerRegistrationService.FormFieldType.NUMBER:
                acc[name] = value;
                break;
            case FleetOwnerRegistrationService.FormFieldType.CHECKBOX:
                acc[name] = Boolean(value);
                break;
            case FleetOwnerRegistrationService.FormFieldType.FILEUPLOAD:
            default:
                break;
        }
        return acc;
    }, {} as FormValueType);
};

function getFormToApiValues<T>(formData: FormValueType, formFields: FleetOwnerRegistrationService.FormField[]): T {
    const request = Object.entries(formData).reduce<Record<string, string | boolean | string[]>>(
        (acc, [key, value]) => {
            const field = formFields.filter((f) => !f.hidden).find((f) => f.name === key);
            if (!field) {
                return acc;
            }

            const formFieldType = field.type;
            switch (formFieldType) {
                case FleetOwnerRegistrationService.FormFieldType.TEXT: {
                    const {prefix_combo_box} = field;
                    if (!prefix_combo_box) {
                        acc[key] = value as string;
                    } else {
                        const {name: prefixName} = prefix_combo_box;
                        const {comboboxValue, textValue} = value as ComboboxTextValue;
                        acc[key] = textValue as string;
                        acc[prefixName] = (comboboxValue as SelectOption)?.value as string;
                    }
                    break;
                }
                case FleetOwnerRegistrationService.FormFieldType.COMBOBOX:
                    if (field.multi) {
                        acc[key] = value as string[];
                    } else {
                        acc[key] = value as string;
                    }
                    break;
                case FleetOwnerRegistrationService.FormFieldType.CHECKBOXGROUP:
                    acc[key] = value as string[];
                    break;
                case FleetOwnerRegistrationService.FormFieldType.RADIOGROUP:
                case FleetOwnerRegistrationService.FormFieldType.PASSWORD:
                case FleetOwnerRegistrationService.FormFieldType.EMAIL:
                case FleetOwnerRegistrationService.FormFieldType.NUMBER:
                    acc[key] = value as string;
                    break;
                case FleetOwnerRegistrationService.FormFieldType.CHECKBOX:
                    acc[key] = Boolean(value);
                    break;
                case FleetOwnerRegistrationService.FormFieldType.FILEUPLOAD:
                case FleetOwnerRegistrationService.FormFieldType.IMAGEUPLOAD:
                case FleetOwnerRegistrationService.FormFieldType.INFOTEXT:
                case FleetOwnerRegistrationService.FormFieldType.INFOTEXTGROUP:
                    break;
                default:
                    UnreachableCode.never(formFieldType);
                    break;
            }
            return acc;
        },
        {},
    );
    return request as T;
}

const getErrorMessage = (
    fieldConfig: FleetOwnerRegistrationService.BaseFormField,
    validatorErrors: ValidatorResult[],
): string | undefined => {
    const {name} = fieldConfig;
    const validationError = validatorErrors.filter((error) => error.property === name)?.[0];
    return validationError?.error;
};

const getChildrenErrorMessages = (
    fieldConfig: FleetOwnerRegistrationService.BaseFormField,
    validatorErrors: ValidatorResult[],
): ValidatorResult[] => {
    const {name} = fieldConfig;
    const childrenErrors = validatorErrors.filter((error) => error.property === name)?.[0]?.children;
    return childrenErrors || [];
};

const getFormField = (
    field: FleetOwnerRegistrationService.FormField,
    validatorResults: ValidatorResult[],
    useFormValue: UseFormValueType,
    onTextInputBlur?: React.FocusEventHandler<HTMLInputElement> | undefined,
) => {
    const {type} = field;
    switch (type) {
        case FleetOwnerRegistrationService.FormFieldType.TEXT:
            return (
                <Text
                    useFormValue={useFormValue}
                    fieldConfig={field}
                    onBlur={onTextInputBlur}
                    validatorErrors={validatorResults}
                />
            );
        case FleetOwnerRegistrationService.FormFieldType.NUMBER:
            return <Number useFormValue={useFormValue} fieldConfig={field} onBlur={onTextInputBlur} />;
        case FleetOwnerRegistrationService.FormFieldType.INFOTEXT:
            return <InfoText fieldConfig={field} />;
        case FleetOwnerRegistrationService.FormFieldType.INFOTEXTGROUP:
            return <InfoGroupText fieldConfig={field} />;
        case FleetOwnerRegistrationService.FormFieldType.RADIOGROUP:
            return (
                <Radio
                    useFormValue={useFormValue}
                    fieldConfig={field}
                    error={getErrorMessage(field, validatorResults)}
                />
            );
        case FleetOwnerRegistrationService.FormFieldType.CHECKBOXGROUP:
            return (
                <CheckboxGroup
                    useFormValue={useFormValue}
                    fieldConfig={field}
                    error={getErrorMessage(field, validatorResults)}
                />
            );
        case FleetOwnerRegistrationService.FormFieldType.CHECKBOX:
            return <Checkbox useFormValue={useFormValue} fieldConfig={field} />;
        case FleetOwnerRegistrationService.FormFieldType.COMBOBOX:
            return (
                <Combobox
                    useFormValue={useFormValue}
                    fieldConfig={field}
                    error={getErrorMessage(field, validatorResults)}
                />
            );
        case FleetOwnerRegistrationService.FormFieldType.PASSWORD:
            return (
                <Password
                    useFormValue={useFormValue}
                    fieldConfig={field}
                    validatorErrors={getChildrenErrorMessages(field, validatorResults)}
                />
            );
        case FleetOwnerRegistrationService.FormFieldType.EMAIL:
            return <Email useFormValue={useFormValue} fieldConfig={field} />;
        default:
            return UnreachableCode.never(type as never);
    }
};

const getFilteredAndSortedFields = (fields: FleetOwnerRegistrationService.FormField[]) =>
    fields.filter((field) => !field.hidden).sort((f1, f2) => f1.position - f2.position);

export {
    getApiToFormValues,
    getFormToApiValues,
    getErrorMessage,
    getChildrenErrorMessages,
    getFormField,
    getFilteredAndSortedFields,
};
