import {useContext} from "react";

import {useI18n} from "common/hooks/useI18n";

import {FleetOwnerRegistrationService, VehicleMarketplaceService} from "@bolteu/bolt-server-api-fleet-owner-portal";
import {Alert, Button, Typography} from "@bolteu/kalep-react";

import {ListingContextProvider} from "./ListingInfoProvider";
import WizardFileupload from "./WizardFileupload";

import ListingStep = VehicleMarketplaceService.ListingStep;

interface WizardReviewContentProps {
    steps: ListingStep[];
    onPublishListing: () => void;
    onPrevStep: () => void;
    isPublishLoading: boolean;
}

const WizardReviewContent = ({steps, onPublishListing, onPrevStep, isPublishLoading}: WizardReviewContentProps) => {
    const {i18n} = useI18n();
    const {listing} = useContext(ListingContextProvider);

    const getFieldContent = (field: FleetOwnerRegistrationService.FormField) => {
        const {type} = field;

        switch (type) {
            case FleetOwnerRegistrationService.FormFieldType.IMAGEUPLOAD:
                return <WizardFileupload isReadOnly />;
            case FleetOwnerRegistrationService.FormFieldType.RADIOGROUP:
            case FleetOwnerRegistrationService.FormFieldType.COMBOBOX: {
                if (field.type === FleetOwnerRegistrationService.FormFieldType.COMBOBOX && field.multi) {
                    const values = field.options
                        .filter((option) => field.values?.includes(option.value))
                        .map((option) => option.label);

                    return <li>{`${field.label}: ${values.join(", ")}`}</li>;
                }

                const value = field.options.find((option) => option.value === field.value)?.label || "";

                return <li>{`${field.label}: ${value}`}</li>;
            }
            case FleetOwnerRegistrationService.FormFieldType.TEXT:
            case FleetOwnerRegistrationService.FormFieldType.NUMBER: {
                const value = field.value || "";
                return <li>{`${field.label}: ${value}`}</li>;
            }
            default:
                return <></>;
        }
    };

    const getStepContent = (step: ListingStep) => {
        const {fields} = step;
        return (
            <div key={step.id}>
                <Typography variant="title-primary" fontSize="text-xl">
                    {step.title}
                </Typography>
                <ul className="mt-4 list-disc gap-3 pl-5">
                    {fields.map((field) => (
                        <div key={field.name}>{getFieldContent(field)}</div>
                    ))}
                </ul>
            </div>
        );
    };

    const title = listing
        ? `${listing.data.vehicle_manufacturer} ${listing.data.vehicle_model_name} (${listing.data.vehicle_years?.join(
              ", ",
          )})`
        : "";

    return (
        <div className="mt-8">
            <Alert severity="info">
                <Typography variant="title-primary" fontSize="text-xl">
                    {i18n("auth.app.fleet.vehicle_marketplace.listings.publish.title", {carModel: title})}
                </Typography>
                <p className="mt-2 text-neutral-800">
                    {i18n("auth.app.fleet.vehicle_marketplace.listings.publish.description", {
                        marketplace_link: "Vehicle Marketplace", // TODO: add link when VM is ready
                    })}
                </p>
            </Alert>
            <div className="mt-8 flex max-w-lg flex-col gap-8">{steps.map(getStepContent)}</div>
            <div className="mt-8 flex justify-between">
                <Button variant="secondary" onClick={onPrevStep}>
                    {i18n("common.back")}
                </Button>
                <Button loading={isPublishLoading} type="submit" onClick={onPublishListing}>
                    {i18n("auth.app.fleet.vehicle_marketplace.listings.add.publish")}
                </Button>
            </div>
        </div>
    );
};

export default WizardReviewContent;
