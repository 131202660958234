import {useCallback, useEffect, useMemo, useState} from "react";
import {useParams} from "react-router-dom";

import CloseButton from "common/components/CloseButton";
import FileUpload from "common/components/fileUpload";
import {FileTypes} from "common/constants/mimeTypes";
import {IMAGES_PER_LISTING} from "common/constants/vehicle_marketplace";
import {useFetch} from "common/hooks/useFetch";
import {useI18n} from "common/hooks/useI18n";
import {Api} from "common/services/apiProvider";

import {VehicleMarketplaceService} from "@bolteu/bolt-server-api-fleet-owner-portal";
import {Spinner} from "@bolteu/kalep-react";

const getListingImagesFunction = (api: Api, body: VehicleMarketplaceService.GetListingImagesRequest) =>
    api.vehicleMarketplace.vehicleListingGetImageUrls(body);

const uploadListingImageFunction = (api: Api, body: VehicleMarketplaceService.UploadListingImageRequest, file: File) =>
    api.vehicleMarketplace.vehicleListingUploadImage(body, {file});

const deleteListingImageFunction = (api: Api, body: VehicleMarketplaceService.RemoveListingImageRequest) =>
    api.vehicleMarketplace.vehicleListingRemoveImage(body);

interface WizardFileuploadProps {
    readonly isReadOnly?: boolean;
    readonly validationErrorMessage?: string;
}
export default function WizardFileupload({isReadOnly, validationErrorMessage}: WizardFileuploadProps) {
    const {id} = useParams<{id: string}>();
    const [uploadErrorMessage, setUploadErrorMessage] = useState(validationErrorMessage || "");

    const {fetch: uploadListingImage} = useFetch(uploadListingImageFunction);
    const {data: listingImages, fetch: getListingImages} = useFetch(getListingImagesFunction);
    const {fetch: deleteListingImage} = useFetch(deleteListingImageFunction);

    useEffect(() => {
        if (validationErrorMessage) {
            setUploadErrorMessage(validationErrorMessage);
        }
    }, [validationErrorMessage]);

    const {i18n} = useI18n();

    const [imagesbeingUploaded, setImagesBeingUploaded] = useState<ThumbnailImage[]>([]);

    const loadListingImages = useCallback(() => {
        if (getListingImages) {
            getListingImages({listingId: Number(id)});
        }
    }, [getListingImages, id]);

    const imagesToShow = useMemo<ThumbnailImage[]>(() => {
        const listingUrlsWithThumbnailReady = listingImages?.image_urls?.map((image) => ({
            ...image,
            thumbnail_ready: true,
        }));

        return [...(listingUrlsWithThumbnailReady ?? []), ...imagesbeingUploaded];
    }, [listingImages?.image_urls, imagesbeingUploaded]);

    const onFileUpload = useCallback(
        (file: File) => {
            if (imagesToShow.length + 1 > IMAGES_PER_LISTING) {
                const errorMessage = i18n("upload_file.too_many_images", {maxImages: IMAGES_PER_LISTING});
                setUploadErrorMessage(errorMessage as string);
                return;
            }

            const listingId = Number(id);
            setImagesBeingUploaded((prev) => [
                ...prev,
                {
                    image_id: 0,
                    thumb_400_url: URL.createObjectURL(file),
                    thumb_800_url: URL.createObjectURL(file),
                    thumb_1600_url: URL.createObjectURL(file),
                    thumbnail_ready: false,
                },
            ]);

            setUploadErrorMessage("");

            if (uploadListingImage) {
                uploadListingImage(
                    {
                        listingId,
                    },
                    file,
                ).then(() => {
                    setTimeout(() => {
                        loadListingImages();
                        setImagesBeingUploaded([]);
                    }, 3000);
                });
            }
        },
        [uploadListingImage, id, loadListingImages, i18n, imagesToShow.length],
    );

    useEffect(() => {
        loadListingImages();
    }, [loadListingImages]);

    const onDeleteImage = useCallback(
        (imageId: number) => {
            if (deleteListingImage) {
                deleteListingImage({imageId}).then(() => {
                    loadListingImages();
                });
            }
        },
        [deleteListingImage, loadListingImages],
    );

    return (
        <div className="flex w-full flex-col gap-4 md:gap-14">
            {!isReadOnly && (
                <div className="hidden flex-col gap-4 sm:invisible sm:flex md:visible md:flex lg:visible lg:flex">
                    <FileUpload
                        onFileUpload={onFileUpload}
                        setErrorMessage={setUploadErrorMessage}
                        errorMessage={uploadErrorMessage}
                        fileTypes={[FileTypes.IMAGE]}
                        maxFileSizeB={10 * 1024 * 1024}
                        minDimensions={{width: 1600, height: 1600}}
                    />
                </div>
            )}

            <div className="flex w-full flex-col flex-wrap gap-2 sm:gap-6 md:flex-row">
                {imagesToShow.map((image) => (
                    <UploadedImage
                        key={image.thumb_400_url}
                        listingUrl={{...image, thumbnail_ready: image.thumbnail_ready}}
                        onDelete={onDeleteImage}
                        isReadOnly={isReadOnly}
                    />
                ))}
            </div>

            {!isReadOnly && (
                <div className="flex w-full flex-col gap-4 sm:visible md:invisible lg:invisible">
                    <FileUpload
                        onFileUpload={onFileUpload}
                        setErrorMessage={setUploadErrorMessage}
                        errorMessage={uploadErrorMessage}
                        fileTypes={[FileTypes.IMAGE]}
                        maxFileSizeB={10 * 1024 * 1024}
                        minDimensions={{width: 1600, height: 1600}}
                    />
                </div>
            )}
        </div>
    );
}

interface UploadedImageProps {
    readonly listingUrl: ThumbnailImage;
    readonly isReadOnly?: boolean;
    readonly onDelete: (imageId: number) => void;
}
export function UploadedImage({listingUrl, onDelete, isReadOnly}: UploadedImageProps) {
    const overlayClasses = listingUrl.thumbnail_ready
        ? "sm:opacity-0 sm:bg-transparent md:opacity-0 md:hover:bg-black/10 md:hover:opacity-100"
        : "bg-black/50 sm:opacity-100 sm:bg-black/50 md:opacity-100";

    const handleDelete = useCallback(() => {
        onDelete(listingUrl.image_id);
    }, [onDelete, listingUrl.image_id]);

    return (
        <div className="relative overflow-hidden rounded-lg sm:h-96 sm:w-full md:h-48 md:w-72">
            <img src={listingUrl.thumb_400_url} alt="" className="h-full w-full object-cover" />
            <div
                className={`absolute top-0 right-0 flex h-full w-full cursor-pointer flex-col transition-opacity duration-300 ${overlayClasses}`}
            >
                <div className="flex w-full justify-end pt-1 pr-1">
                    {!isReadOnly && listingUrl.thumbnail_ready && <CloseButton onClick={handleDelete} />}
                </div>
                {!isReadOnly && !listingUrl.thumbnail_ready && (
                    <div className="flex h-full w-full items-center justify-center">
                        <Spinner size={600} />
                    </div>
                )}
            </div>
        </div>
    );
}

interface ThumbnailImage extends VehicleMarketplaceService.ListingImageUrl {
    readonly thumbnail_ready: boolean;
}
