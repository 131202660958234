import {useCallback, useContext, useEffect} from "react";

import {NotificationType} from "common/components/notifications/TopLeftCorner";
import {FixedHeaderTable} from "common/components/styledComponent/FixedHeaderTable";
import {useFetch} from "common/hooks/useFetch";
import {useI18n} from "common/hooks/useI18n";
import {Api} from "common/services/apiProvider";
import {NotificationContext} from "common/services/notificationProvider";
import {AccountContextProvider} from "features/account/accountStateProvider";

import {OrderFleetService} from "@bolteu/bolt-server-api-fleet-owner-portal";

import NoOrders from "../../components/NoOrders";
import {IncomingOrdersType, PollingInterval} from "..";
import {useTableActions, useTableColumns} from "../hooks/useManualAcceptOrderTableColumns";

interface Props {
    setIncomingOrdersType: (type: IncomingOrdersType) => void;
}

export interface ManualAcceptedOrdersTableData {
    id: number;
    driver_id: number;
    driver_name: string;
    address: string | null;
    incoming_time: number;
    client_name: string | null;
}

const getOrdersFetchFunction = (api: Api) => api.orderFleetShardedApi.getPendingOrders();
const acceptOrderFetchFunction = (api: Api, body: OrderFleetService.FleetAcceptOrderRequest) =>
    api.orderFleetShardedApi.acceptOrder(body);
const rejectOrderFetchFunction = (api: Api, body: OrderFleetService.FleetRejectOrderRequest) =>
    api.orderFleetShardedApi.rejectOrder(body);

const ManualAcceptIncomingOrdersTable = ({setIncomingOrdersType}: Props) => {
    const {i18n} = useI18n();
    const columns = useTableColumns();
    const {setNotification} = useContext(NotificationContext);

    const fleet = useContext(AccountContextProvider)?.getFleet();

    const {data, fetch} = useFetch(getOrdersFetchFunction);
    const {fetch: acceptOrder} = useFetch(acceptOrderFetchFunction);
    const {fetch: rejectOrder} = useFetch(rejectOrderFetchFunction);

    useEffect(() => {
        let interval: number | undefined;
        if (fleet && fetch) {
            fetch({});
            interval = window.setInterval(() => {
                fetch({});
            }, PollingInterval);
        }

        return () => {
            clearInterval(interval);
        };
    }, [fetch, fleet]);

    useEffect(() => {
        if (data) {
            setIncomingOrdersType(
                data?.is_auto_accept === 1 ? IncomingOrdersType.AUTO_ACCEPTED_ORDERS : IncomingOrdersType.MANUAL_ORDERS,
            );
        }
    }, [data, setIncomingOrdersType]);

    const renderEmptyPlaceholder = useCallback(() => {
        return <NoOrders />;
    }, []);

    const getFields = (order: OrderFleetService.FleetPendingOrderRow): ManualAcceptedOrdersTableData => {
        return {
            id: order.order_try_id,
            incoming_time: order.created,
            address: order.address,
            driver_id: order.driver_id,
            driver_name: order.driver_name,
            client_name: order.client_name,
        };
    };

    const items = data?.pending_orders.map(getFields) || [];

    const onApprove = useCallback(
        (orderTryId: number) => () => {
            async function checkAutoAccept() {
                if (fleet && acceptOrder) {
                    await acceptOrder({order_try_id: orderTryId});
                    setNotification({
                        type: NotificationType.SUCCESS,
                        text: i18n("auth.app.orders.incoming.order-accepted"),
                        timeout: 6000,
                    });
                    if (fetch) {
                        await fetch({});
                    }
                }
            }

            checkAutoAccept();
        },
        [fleet, fetch, acceptOrder, setNotification, i18n],
    );

    const onDecline = useCallback(
        (orderTryId: number) => () => {
            async function checkAutoAccept() {
                if (fleet && rejectOrder) {
                    await rejectOrder({order_try_id: orderTryId});
                    setNotification({
                        type: NotificationType.SUCCESS,
                        text: i18n("auth.app.orders.incoming.order-rejected"),
                        timeout: 6000,
                    });
                    if (fetch) {
                        await fetch({});
                    }
                }
            }

            checkAutoAccept();
        },
        [fleet, fetch, rejectOrder, setNotification, i18n],
    );

    const inlineActions = useTableActions(onApprove, onDecline);

    return (
        <FixedHeaderTable
            columns={columns}
            items={items}
            aria-label="Manually accepted orders table"
            renderEmptyPlaceholder={renderEmptyPlaceholder}
            inlineActions={inlineActions}
        />
    );
};

export default ManualAcceptIncomingOrdersTable;
