import {UnreachableCode} from "common/components/util/UnreachableCode";

export enum TimeType {
    HOUR = "hour",
    MINUTE = "minute",
}

const getMaxTime = (type: TimeType) => {
    switch (type) {
        case TimeType.HOUR:
            return 23;
        case TimeType.MINUTE:
            return 59;
        default: {
            UnreachableCode.never(type);
            throw Error("Unsupported time type");
        }
    }
};

export const getHoursOfTheDay = Array.from({length: 24}, (_, i) => ({
    value: i.toString().padStart(2, "0"),
    label: i.toString().padStart(2, "0"),
}));

export const restrictNumberInputToTime = (input: string, type: TimeType) => {
    const maxNumber = getMaxTime(type);
    const firstTwoCharacterNumber = Number(input.substring(0, 2));
    const isFirstTwoCharactersEnough = firstTwoCharacterNumber >= 10 && firstTwoCharacterNumber <= maxNumber;
    if (isFirstTwoCharactersEnough) {
        return input.substring(0, 2);
    }
    return Math.max(0, Math.min(maxNumber, Number(input)))
        .toString()
        .padStart(2, "0");
};
