import {useCallback, useState} from "react";

import DriverFilter from "common/components/DriverFilter";
import Pagination from "common/components/Pagination";
import {PeriodDatePicker} from "common/components/periodDatePicker";
import LoadingSpinner from "common/components/spinner";
import ApiDrivenTable, {AlignHorizontal, AlignVertical} from "common/components/table/ApiDrivenTable/index";
import {useDrivers} from "common/hooks/useDrivers";
import {FetchStatus} from "common/hooks/useFetch";
import {useI18n} from "common/hooks/useI18n";
import {selectPeriodOptionsUntilNow} from "common/hooks/usePeriodSelectorOptions";
import {ApiPeriod} from "common/services/period";

import {DriverSessionHistoryService} from "@bolteu/bolt-server-api-fleet-owner-portal";
import {SelectOption} from "@bolteu/kalep-react";

import DownloadActityLog from "./DownloadActivityLog";
import {NoActivityLogs} from "./NoActivityLogs";

export const ACTIVITY_LOG_TABLE_ID = "activity-log-table";

export interface ActivityLogTableProps {
    columnsResponse: DriverSessionHistoryService.GetSessionsResponse | null;
    pageSize: number;
    currentPage: number;
    onPageChange: (page: number) => void;
    totalCount: number;
    isLoading: boolean;
    selectedPeriod: ApiPeriod;
    selectedDrivers: number[];
    onSearchChange: (driver_ids: number[]) => void;
    onPeriodChange: (period: ApiPeriod) => void;
}

function ActivityLogTable(sessionsTableProps: ActivityLogTableProps) {
    const {currentPage, totalCount, pageSize, onPageChange, isLoading, columnsResponse} = sessionsTableProps;
    const [selectedDriverIds, setSelectedDriverIds] = useState<number[]>([]);

    const {i18n} = useI18n();
    const {drivers, status} = useDrivers(sessionsTableProps.selectedPeriod);

    let sessionsTable;
    if (isLoading) {
        sessionsTable = <LoadingSpinner show parentClassName="mt-2" />;
    } else if (columnsResponse?.columns && totalCount > 0) {
        sessionsTable = (
            <ApiDrivenTable
                columns={columnsResponse?.columns}
                numberOfRecords={columnsResponse?.columns[0].cells.length ?? 0}
                tableId={ACTIVITY_LOG_TABLE_ID}
                alignHorizontal={AlignHorizontal.RIGHT}
                alignVertical={AlignVertical.TOP}
            />
        );
    } else {
        sessionsTable = <NoActivityLogs />;
    }

    const handleDriverIdsChange = useCallback(
        (selectedDrivers: SelectOption[] | null) => {
            const driverIds = selectedDrivers?.map((driver) => driver.value as number) || [];
            sessionsTableProps.onSearchChange(driverIds);
            setSelectedDriverIds(driverIds || []);
        },
        [sessionsTableProps],
    );

    return (
        <>
            <div className="flex flex-col gap-4 md:flex-row md:justify-between">
                <div className="min-w-[180px] lg:min-w-[282px] xl:min-w-[400px]">
                    <DriverFilter
                        driverIds={selectedDriverIds}
                        onChange={handleDriverIdsChange}
                        drivers={drivers}
                        placeholder={i18n("auth.app.fleet.shifts.activity_log.filter_by_drivers")}
                        loading={status === FetchStatus.Loading}
                        includeAllOption={false}
                        multiple
                    />
                </div>
                <div className="flex flex-wrap gap-4">
                    <PeriodDatePicker
                        period={sessionsTableProps.selectedPeriod}
                        selectPeriodOptions={selectPeriodOptionsUntilNow}
                        onPeriodChange={sessionsTableProps.onPeriodChange}
                        dateRangeLimitDays={31}
                    />
                    <DownloadActityLog
                        period={sessionsTableProps.selectedPeriod}
                        driverIds={sessionsTableProps.selectedDrivers}
                    />
                </div>
            </div>
            {sessionsTable}
            <Pagination
                currentPage={currentPage}
                pageSize={pageSize}
                totalCount={totalCount}
                onPageChange={onPageChange}
                disabled={isLoading}
                showText
            />
        </>
    );
}

export default ActivityLogTable;
