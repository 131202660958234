import {useCallback, useEffect, useState} from "react";

import {ComboboxText} from "common/components/dynamicForm/ComboboxText";
import {AdditionalFields, FormValueType} from "common/components/dynamicForm/types";
import {
    getApiToFormValues,
    getErrorMessage,
    getFilteredAndSortedFields,
    getFormField,
    getFormToApiValues,
} from "common/components/dynamicForm/util";
import {ValidatorResult} from "common/constants/types";
import {FetchStatus, useFetch} from "common/hooks/useFetch";
import {useI18n} from "common/hooks/useI18n";
import {Api} from "common/services/apiProvider";

import {FleetOwnerRegistrationService, VehicleMarketplaceService} from "@bolteu/bolt-server-api-fleet-owner-portal";
import {FormChange, FormState, useForm} from "@bolteu/kalep-form-react";
import {Button, Typography} from "@bolteu/kalep-react";

import WizardFileupload from "./WizardFileupload";

import ListingStep = VehicleMarketplaceService.ListingStep;

export const vehicleListingUpdateSubmitFunction = (api: Api, body: VehicleMarketplaceService.UpdateListingRequest) =>
    api.vehicleMarketplace.vehicleListingUpdate(body);

interface Props {
    step: ListingStep;
    listingId: number;
    onNextStep: () => void;
    onPrevStep: () => void;
}

const WizardContentHeader = ({title, subtitle}: {title: string; subtitle?: string}) => {
    return (
        <div className="flex flex-col gap-6">
            <Typography variant="title-primary" fontSize="text-3xl">
                {title}
            </Typography>
            {subtitle && <Typography variant="body-primary">{subtitle}</Typography>}
        </div>
    );
};

const WizardContent = ({step, listingId, onNextStep, onPrevStep}: Props) => {
    const {i18n} = useI18n();
    const {fields} = step;

    const {fetch: submit, status, error: submitError} = useFetch(vehicleListingUpdateSubmitFunction);

    const [formFields] = useState<FleetOwnerRegistrationService.FormField[]>(fields);
    const [validatorResults, setValidatorResults] = useState<ValidatorResult[]>([]);

    useEffect(() => {
        if (submitError.validationErrors) {
            setValidatorResults(submitError.validationErrors);
        }
    }, [submitError.validationErrors]);

    useEffect(() => {
        if (status === FetchStatus.Success) {
            onNextStep();
        }
    }, [onNextStep, status]);

    const onSubmitForm = useCallback(
        async (state: FormState<FormValueType>) => {
            if (!submit) {
                return;
            }
            const {data} = state;
            const request: VehicleMarketplaceService.UpdateListingRequest = {
                data: getFormToApiValues<VehicleMarketplaceService.UpdateListingData>(data, formFields),
                listing_id: listingId,
                step_id: step.id,
                should_validate_required: true,
            };

            await submit(request);
        },
        [formFields, submit, listingId, step.id],
    );

    const [formValue, setFormValue] = useState<FormValueType>(getApiToFormValues(formFields));

    const onChange = useCallback(async (formData: FormChange<FormValueType>) => {
        const {data} = formData;
        setFormValue(data);
    }, []);

    const useFormValue = useForm<FormValueType, AdditionalFields>(formValue, {
        ComboboxTextField: ComboboxText,
    });
    const {Form, Button: FormButton} = useFormValue;

    const getField = (field: FleetOwnerRegistrationService.FormField) => {
        const {type} = field;
        if (type === FleetOwnerRegistrationService.FormFieldType.IMAGEUPLOAD) {
            return <WizardFileupload validationErrorMessage={getErrorMessage(field, validatorResults)} />;
        }
        return getFormField(field, validatorResults, useFormValue);
    };

    return (
        <div className="w-full">
            <Form validatorResults={validatorResults} onSubmit={onSubmitForm} onChange={onChange}>
                <div className="mt-6 flex w-full flex-col gap-6">
                    {getFilteredAndSortedFields(formFields).map((field) => (
                        <div key={field.name} className="w-full">
                            {getField(field)}
                        </div>
                    ))}
                </div>
                <div className="mt-8 flex justify-between">
                    {step.position > 1 && (
                        <Button variant="secondary" onClick={onPrevStep}>
                            {i18n("common.back")}
                        </Button>
                    )}
                    <FormButton submit>{i18n("form.button.next")}</FormButton>
                </div>
            </Form>
        </div>
    );
};

export default WizardContent;
export {WizardContentHeader};
