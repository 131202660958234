import {FC, useCallback, useContext} from "react";

import {EventName, EventPropertiesL2FShowMoreFrom} from "common/constants/events";
import {useI18n} from "common/hooks/useI18n";
import {useTracking} from "common/hooks/useTracking";
import {NotificationContext} from "common/services/notificationProvider";

import {DriverRegistration} from "../index";
import DriverRegistrationsTable from "./DriverRegistrationsTable";
import Matches from "./matches/Matches";
import {MatchesContextProvider} from "./matches/matchesProvider";
import OptIn from "./matches/OptIn";

interface DriverRegistrationsTabProps {
    driverRegistrations?: DriverRegistration[];
    onLeadAccepted: () => void;
    showRegistrationsInfo: () => void;
}

const DriverRegistrationsTab: FC<DriverRegistrationsTabProps> = ({
    driverRegistrations,
    onLeadAccepted,
    showRegistrationsInfo,
}) => {
    const {i18n} = useI18n();
    const {trackEvent} = useTracking();
    const {setNotification} = useContext(NotificationContext);
    const matchesState = useContext(MatchesContextProvider);

    const showInfoPage = useCallback(() => {
        showRegistrationsInfo();
        trackEvent(EventName.L2FShowMoreClicked, {calledFrom: EventPropertiesL2FShowMoreFrom.DriverRegistrations});
    }, [showRegistrationsInfo, trackEvent]);

    return (
        <>
            {!!driverRegistrations?.length && (
                <div className="mb-3 flex flex-col">
                    <h2 className="text-lg font-semibold">
                        {i18n("auth.app.fleet.driver-registrations.invited-drivers")}
                    </h2>
                    <DriverRegistrationsTable driverRegistrations={driverRegistrations} />
                </div>
            )}
            {matchesState?.isAvailable && (
                <>
                    <h2 className="text-lg font-semibold">
                        {i18n("auth.app.fleet.driver-registrations.proposed-candidates.title")}
                    </h2>
                    {matchesState?.hasOptInChance && <OptIn showInfoPage={showInfoPage} />}
                    {!matchesState?.hasOptInChance && (
                        <Matches
                            showNotification={setNotification}
                            showInfoPage={showInfoPage}
                            onLeadAccepted={onLeadAccepted}
                        />
                    )}
                </>
            )}
        </>
    );
};

export default DriverRegistrationsTab;
