import {useMemo, useRef} from "react";
import {useNavigate} from "react-router-dom";

import FilledCircle from "assets/icons/filled_circle.svg?react";
import {useAbsolutePath} from "common/hooks/useAbsolutePath";
import {useI18n} from "common/hooks/useI18n";
import {useTooltipClick} from "common/hooks/useTooltipClick";

import {FleetOwnerPortalService} from "@bolteu/bolt-server-api-fleet-owner-portal";
import {Accordion, AccordionItem, Button, Menu, MenuItem, Tooltip, Typography} from "@bolteu/kalep-react";
import {InfoCircleOutlined} from "@bolteu/kalep-react-icons";
import SvgChevronDown from "@bolteu/kalep-react-icons/dist/ChevronDown";

import {
    CellProps,
    CellRenderer,
    generateSimpleKey,
    isDateColumn,
    isReferenceColumn,
    MobileSubContentRenderer,
    ReferenceCellRenderer,
    StateDisplayText,
    UseableColumns,
} from "./Common";
import ApiColumn = FleetOwnerPortalService.ApiColumn;
import ReferenceType = FleetOwnerPortalService.ReferenceType;
import ReferenceEntity = FleetOwnerPortalService.ReferenceEntity;
import ReferenceColumn = FleetOwnerPortalService.ReferenceColumn;
import EntityState = FleetOwnerPortalService.EntityState;
import StateColumn = FleetOwnerPortalService.StateColumn;

type ApiDrivenTableMobileProps = UseableColumns & {
    numberOfRecords: number;
};

export function ApiDrivenTableMobile(apiDrivenTableMobileProps: ApiDrivenTableMobileProps) {
    const {i18n} = useI18n();
    const navigate = useNavigate();
    const {getDriverDetailsPath, getVehicleDetailsPath} = useAbsolutePath();
    const {userDefinedVisibleColumns, subColumns, stateColumns, numberOfRecords} = apiDrivenTableMobileProps;

    const getSubColumns = useMemo(
        () =>
            userDefinedVisibleColumns.reduce((acc, column) => {
                acc[column.key] = subColumns.filter((c) => c.relation_key === column.key);
                return acc;
            }, {} as Record<string, FleetOwnerPortalService.ApiColumn[]>),
        [userDefinedVisibleColumns, subColumns],
    );

    const generateProfilePath = (referenceType: ReferenceType, column: ApiColumn, rowIdx: number): string => {
        const entity = column.cells[rowIdx] as ReferenceEntity;
        switch (referenceType) {
            case ReferenceType.DRIVER:
                return getDriverDetailsPath(Number(entity.id));
            case ReferenceType.VEHICLE:
                return getVehicleDetailsPath(Number(entity.id));
            default:
                throw new Error(`Unhandled reference type: ${referenceType}`);
        }
    };

    const goToProfile = (referenceType: ReferenceType, column: ApiColumn, rowIdx: number) => {
        const path = generateProfilePath(referenceType, column, rowIdx);
        return () => navigate(path);
    };

    const getMenuItem = (column: ReferenceColumn, rowIdx: number) => {
        const openProfile =
            column.reference_type === ReferenceType.DRIVER
                ? i18n("auth.app.fleet.shifts.activity_log.driver_profile")
                : i18n("auth.app.fleet.shifts.activity_log.vehicle_profile");

        return (
            <MenuItem
                key={column.cells[rowIdx].id}
                label={openProfile}
                onClick={goToProfile(column.reference_type, column, rowIdx)}
            />
        );
    };

    const getProfileLinks = (referenceColumns: ReferenceColumn[], rowIdx: number) => {
        const profileButton = (
            <Button variant="secondary" endIcon={<SvgChevronDown />}>
                {i18n("auth.app.fleet.shifts.activity_log.see_profiles")}
            </Button>
        );

        if (referenceColumns.length > 1) {
            return (
                <div className="pt-5">
                    <Menu menuButton={profileButton}>
                        {referenceColumns.map((column) => getMenuItem(column, rowIdx))}
                    </Menu>
                </div>
            );
        }

        if (referenceColumns.length === 1) {
            const column = referenceColumns[0];
            return (
                <button type="button" onClick={goToProfile(column.reference_type, column, rowIdx)}>
                    <div className="text-action-primary">
                        {column.reference_type === ReferenceType.DRIVER
                            ? i18n("auth.app.fleet.shifts.activity_log.driver_profile")
                            : i18n("auth.app.fleet.shifts.activity_log.vehicle_profile")}
                    </div>
                </button>
            );
        }

        return null;
    };

    function getAccordionTitle(rowIdx: number) {
        const visibleColumns = userDefinedVisibleColumns.filter((column) => !isReferenceColumn(column));
        const referenceColumns = userDefinedVisibleColumns.filter(
            (column) => isDateColumn(column) || isReferenceColumn(column),
        );

        return (
            <div className="flex-col items-center gap-3">
                {referenceColumns.map((column, idx) => {
                    const matchingStateColumn = stateColumns?.find(
                        (stateColumn) => stateColumn.relation_key === column.key,
                    );

                    return (
                        <div key={column.key} className="flex items-center gap-x-1">
                            {isReferenceColumn(column) ? (
                                <>
                                    {isStateAlertOrInactive(rowIdx, matchingStateColumn) && (
                                        <>
                                            <StateDisplayText stateColumn={matchingStateColumn} valueAtIdx={rowIdx} />
                                            <FilledCircle
                                                width={4}
                                                height={4}
                                                className="self-center fill-neutral-700"
                                            />
                                        </>
                                    )}
                                    <ReferenceCellRenderer column={column} valueAtIdx={rowIdx} isSecondary={idx > 0} />
                                </>
                            ) : (
                                <CellRenderer valueAtIdx={rowIdx} column={column} />
                            )}
                        </div>
                    );
                })}
                {shouldRenderVisibleColumns(referenceColumns, visibleColumns) && (
                    <Typography variant="body-tabular-s-regular" color="secondary">
                        <span className="mr-1">{`${visibleColumns[0].title}:`}</span>
                        <span className="inline-flex items-baseline">
                            <CellRenderer column={visibleColumns[0]} valueAtIdx={rowIdx} isSubCell />
                        </span>
                    </Typography>
                )}
            </div>
        );
    }

    function isStateAlertOrInactive(rowIdx: number, stateColumn?: StateColumn) {
        return stateColumn && [EntityState.ALERT, EntityState.INACTIVE].includes(stateColumn.cells[rowIdx]);
    }

    function shouldRenderVisibleColumns(referenceColumns: ApiColumn[], visibleColumns: ApiColumn[]) {
        return referenceColumns.length <= 1 && visibleColumns[0];
    }

    return (
        <div className="w-full">
            {Array.from({length: numberOfRecords}).map((_, rowIdx) => (
                <Accordion key={generateSimpleKey(rowIdx)}>
                    <AccordionItem
                        id={`${rowIdx}`}
                        title={{
                            primary: getAccordionTitle(rowIdx),
                        }}
                    >
                        <ul className="m-0 flex list-none flex-col gap-4 p-0">
                            {userDefinedVisibleColumns
                                .filter((column) => !isReferenceColumn(column) && !isDateColumn(column))
                                .map((column) => (
                                    <li key={column.key}>
                                        <AccordionTableCell
                                            column={column}
                                            valueAtIdx={rowIdx}
                                            subColumns={getSubColumns[column.key]}
                                        />
                                    </li>
                                ))}
                        </ul>
                        <div className="pt-5">
                            {getProfileLinks(
                                userDefinedVisibleColumns.filter(isReferenceColumn) as ReferenceColumn[],
                                rowIdx,
                            )}
                        </div>
                    </AccordionItem>
                </Accordion>
            ))}
        </div>
    );
}

export function AccordionTableCell(
    cellProps: CellProps & {
        subColumns: FleetOwnerPortalService.ApiColumn[];
    },
) {
    const ref = useRef<HTMLElement | undefined>(undefined);
    function addRef() {
        return (r: HTMLElement | null) => {
            if (r) {
                ref.current = r;
            }
        };
    }

    const {isOpen, setIsOpen, onClick} = useTooltipClick({delay: 3000});

    return (
        <>
            <div className="flex gap-y-1 gap-x-2">
                <span className="text-content-secondary m-0 flex items-center gap-2 text-sm font-normal" ref={addRef()}>
                    {cellProps.column.title}
                    {cellProps.column.tooltip && (
                        <Tooltip
                            placement="top"
                            isOpen={isOpen}
                            onOpenChange={setIsOpen}
                            boundaryElement={ref.current}
                            content={cellProps.column.tooltip}
                        >
                            <div>
                                <InfoCircleOutlined width={16} height={16} onClick={onClick} />
                            </div>
                        </Tooltip>
                    )}
                </span>
                <div className="border-separator min-w-[8px] flex-1 grow border-0 border-b" />
                <span>
                    <CellRenderer {...cellProps} />
                </span>
            </div>
            <MobileSubContentRenderer {...cellProps} />
        </>
    );
}
