import React from "react";
import {useIntl} from "react-intl";
import {Link} from "react-router-dom";

import FilledCircle from "assets/icons/filled_circle.svg?react";
import {AlignHorizontal} from "common/components/table/ApiDrivenTable/index";
import {useAbsolutePath} from "common/hooks/useAbsolutePath";
import {useI18n} from "common/hooks/useI18n";
import {localStorageService} from "common/services/LocalStorageService";
import {apiTimestampToDate} from "common/util/apiTimestampToDate";
import {formatNumber, getDayMonthYearDate, getTime} from "common/util/FormatUtil";
import {useLocalStorage} from "@fleet/common/hooks/useLocalStorage";

import {FleetOwnerPortalService} from "@bolteu/bolt-server-api-fleet-owner-portal";
import {Typography} from "@bolteu/kalep-react";

import {TableColumnSettings} from "../TableWithCustomizableColumns";
import EntityState = FleetOwnerPortalService.EntityState;
import ApiColumn = FleetOwnerPortalService.ApiColumn;
import ListColumnType = FleetOwnerPortalService.ListColumnType;
import StateColumn = FleetOwnerPortalService.StateColumn;
import ReferenceColumn = FleetOwnerPortalService.ReferenceColumn;
import ReferenceType = FleetOwnerPortalService.ReferenceType;
import Accessibility = FleetOwnerPortalService.Accessibility;
import ReferenceEntity = FleetOwnerPortalService.ReferenceEntity;
import NumberType = FleetOwnerPortalService.NumberType;

export interface CellProps {
    valueAtIdx: number;
    column: ApiColumn;
}

interface StateDisplayIndicatorProps {
    entityState?: EntityState;
}

export function StringCellContent({value, isSubCell}: {value: string; isSubCell?: boolean}) {
    const fontSize = isSubCell ? "text-xs" : "text-sm";
    const color = isSubCell ? "secondary" : undefined;
    return (
        <Typography variant="body-tabular-m-regular" fontSize={fontSize} color={color}>
            {value}
        </Typography>
    );
}

export function NumberCellContent({
    unit,
    alignHorizontal,
    numberType,
    value,
    isSubCell,
}: {
    value: number;
    unit?: string;
    numberType: NumberType;
    isSubCell?: boolean;
    alignHorizontal?: AlignHorizontal;
}): React.ReactElement {
    const intl = useIntl();
    const options = numberType === NumberType.INTEGER ? {minFractionDigits: 0, maxFractionDigits: 0} : {};
    const formattedValue = formatNumber(intl, value, options);

    const fontSize = isSubCell ? "text-xs" : "text-sm";
    const color = isSubCell ? "secondary" : undefined;

    const horizontalAlign = alignHorizontal === AlignHorizontal.RIGHT ? "justify-end" : "justify-start";

    if (!value) {
        return (
            <Typography variant="body-tabular-s-regular" color="tertiary" fontSize={fontSize}>
                {formattedValue}
            </Typography>
        );
    }

    return (
        <div className={`flex w-full items-baseline gap-x-1 ${horizontalAlign}`}>
            <Typography variant="body-tabular-m-regular" color={color} fontSize={fontSize}>
                {formattedValue}
            </Typography>{" "}
            <Typography variant="body-tabular-s-regular" color="secondary" fontSize="text-xs">
                {unit}
            </Typography>
        </div>
    );
}

export function DurationCellContent({
    value,
    isSubCell,
    alignHorizontal,
}: {
    value: number;
    isSubCell?: boolean;
    alignHorizontal?: AlignHorizontal;
}): React.ReactElement {
    const {i18n} = useI18n();

    const hours = Math.floor(value / 3600);
    const minutes = Math.floor((value % 3600) / 60);

    const hoursLabel = i18n("common.hour_abbreviation");
    const minutesLabel = i18n("common.minute_abbreviation");

    const fontSize = isSubCell ? "text-xs" : "text-sm";
    const color = isSubCell ? "secondary" : undefined;

    return (
        <div
            className={`flex ${
                alignHorizontal === AlignHorizontal.RIGHT ? "justify-end" : "justify-start"
            } gap-1 whitespace-nowrap`}
        >
            {hours !== 0 ? (
                <div className="flex items-baseline gap-x-1">
                    <Typography variant="body-tabular-m-regular" color={color} fontSize={fontSize}>
                        {hours}
                    </Typography>
                    <Typography variant="body-tabular-s-regular" color="secondary" fontSize="text-xs">
                        {` ${hoursLabel}`}
                    </Typography>
                </div>
            ) : null}
            <div className="flex items-baseline gap-x-1">
                <Typography variant="body-tabular-m-regular" color={color} fontSize={fontSize}>
                    {minutes}
                </Typography>
                <Typography variant="body-tabular-s-regular" color="secondary" fontSize="text-xs">
                    {minutesLabel}
                </Typography>
            </div>
        </div>
    );
}

export function DateCellContent({value, isSubCell}: {value: number; isSubCell?: boolean}): React.ReactElement {
    const fontSize = isSubCell ? "text-xs" : "text-sm";
    const color = isSubCell ? "secondary" : undefined;

    return (
        <div className="align-right flex justify-start gap-1 whitespace-nowrap text-left">
            <div className="items-baseline gap-x-1">
                <Typography variant="body-tabular-m-regular" color={color} fontSize={fontSize}>
                    {`${getDayMonthYearDate(apiTimestampToDate(value))}, ${getTime(apiTimestampToDate(value))}`}
                </Typography>
            </div>
        </div>
    );
}

export function getVehicleStatusIcon(status: EntityState) {
    switch (status) {
        case EntityState.DEFAULT:
            return <></>;
        case EntityState.ALERT:
            return <FilledCircle width={12} height={12} className="fill-red-500" />;
        case EntityState.INACTIVE:
            return <FilledCircle width={12} height={12} className="fill-neutral-500" />;
        default:
            return <></>;
    }
}

export function CellRenderer(
    cellProps: CellProps & {
        isSubCell?: boolean;
        isReferenceCell?: boolean;
        alignHorizontal?: AlignHorizontal;
    },
) {
    switch (cellProps.column.type) {
        case ListColumnType.STRING:
            return (
                <StringCellContent
                    isSubCell={cellProps.isSubCell}
                    value={cellProps.column.cells[cellProps.valueAtIdx]}
                />
            );
        case ListColumnType.NUMBER:
            return (
                <NumberCellContent
                    unit={cellProps.column.unit}
                    isSubCell={cellProps.isSubCell}
                    alignHorizontal={cellProps.alignHorizontal}
                    numberType={cellProps.column.number_type}
                    value={cellProps.column.cells[cellProps.valueAtIdx]}
                />
            );
        case ListColumnType.DURATION:
            return (
                <DurationCellContent
                    isSubCell={cellProps.isSubCell}
                    alignHorizontal={cellProps.alignHorizontal}
                    value={cellProps.column.cells[cellProps.valueAtIdx]}
                />
            );
        case ListColumnType.DATE:
            return (
                <DateCellContent isSubCell={cellProps.isSubCell} value={cellProps.column.cells[cellProps.valueAtIdx]} />
            );
        default:
            return <></>;
    }
}

export function SubContentRenderer({
    subColumns,
    valueAtIdx,
    isReferenceCell,
}: {
    valueAtIdx: number;
    subColumns: ApiColumn[];
    isReferenceCell?: boolean;
}) {
    const subContent = subColumns?.map((column) => {
        return (
            <div key={column.key}>
                {column.bind_title && <span className="mr-1">{`${column.bind_title}:`}</span>}
                <span className="inline-flex items-baseline">
                    <CellRenderer column={column} valueAtIdx={valueAtIdx} isSubCell />
                </span>
            </div>
        );
    });

    return (
        <div className={isReferenceCell ? "" : "mt-2"}>
            <Typography variant="body-tabular-s-regular" color="secondary" fontSize="text-xs">
                {subContent && subContent.length > 0 && subContent}
            </Typography>
        </div>
    );
}

export function MobileSubContentRenderer({subColumns, valueAtIdx}: {subColumns: ApiColumn[]; valueAtIdx: number}) {
    const subContent = subColumns?.map((column) => {
        return (
            <div key={column.key} className="ml-3 mt-1 flex items-baseline gap-y-1 gap-x-2">
                <span>{column.bind_title && `${column.bind_title}:`}</span>
                <div className="border-separator min-w-[8px] flex-1 grow border-0 border-b" />
                <span className="inline-flex">
                    <CellRenderer column={column} valueAtIdx={valueAtIdx} isSubCell />
                </span>
            </div>
        );
    });

    return (
        <div className="mt-2">
            <Typography variant="body-tabular-s-regular" color="secondary">
                {subContent && subContent.length > 0 && subContent}
            </Typography>
        </div>
    );
}

export function ReferenceCellRenderer(
    cellProps: CellProps & {
        isSecondary?: boolean;
        displayAsLink?: boolean;
        stateColumn?: StateColumn;
    },
) {
    const {getDriverDetailsPath, getVehicleDetailsPath} = useAbsolutePath();

    const getLinkTo = () => {
        const {reference_type, cells} = (cellProps.column as ReferenceColumn) || {};
        const entityId = cells?.[cellProps.valueAtIdx]?.id;

        if (!entityId || Number.isNaN(Number(entityId))) {
            return undefined;
        }

        switch (reference_type) {
            case ReferenceType.DRIVER:
                return getDriverDetailsPath(Number(entityId));
            case ReferenceType.VEHICLE:
                return getVehicleDetailsPath(Number(entityId));
            default:
                return undefined;
        }
    };

    const linkTo = getLinkTo();
    const cellData = cellProps.column.cells?.[cellProps.valueAtIdx];
    const name = (cellData as ReferenceEntity)?.name || "";

    const primaryCellValue = (
        <Typography
            fontSize="text-sm"
            variant="body-tabular-m-regular"
            color={cellProps.isSecondary ? "secondary" : undefined}
        >
            {name}
        </Typography>
    );

    return (
        <div className="flex items-center gap-3">
            {cellProps.stateColumn?.cells[cellProps.valueAtIdx] && (
                <StateDisplayIndicator entityState={cellProps.stateColumn.cells[cellProps.valueAtIdx]} />
            )}
            <div className="flex flex-col">
                {cellProps.displayAsLink && linkTo ? (
                    <Link className="hover:underline" to={linkTo}>
                        {primaryCellValue}
                    </Link>
                ) : (
                    primaryCellValue
                )}
                {cellProps.stateColumn && (
                    <StateDisplayText stateColumn={cellProps.stateColumn} valueAtIdx={cellProps.valueAtIdx} />
                )}
            </div>
        </div>
    );
}

export function StateDisplayIndicator({entityState}: StateDisplayIndicatorProps) {
    return entityState ? getVehicleStatusIcon(entityState) : <></>;
}

export function StateDisplayText(stateDisplayProps: {valueAtIdx: number; stateColumn?: StateColumn}) {
    if (!stateDisplayProps.stateColumn) {
        return <></>;
    }

    const {value_map} = stateDisplayProps.stateColumn;
    const valueMapPair = value_map.find(
        (value) => value.key === stateDisplayProps.stateColumn?.cells[stateDisplayProps.valueAtIdx],
    );

    if (!valueMapPair) {
        return <></>;
    }

    switch (valueMapPair.key) {
        case EntityState.ALERT:
            return (
                <Typography variant="body-tabular-s-regular" color="danger-primary">
                    {valueMapPair.value}
                </Typography>
            );
        case EntityState.INACTIVE:
            return (
                <Typography variant="body-tabular-s-regular" color="secondary">
                    {valueMapPair.value}
                </Typography>
            );
        case EntityState.DEFAULT:
            return <></>;
        default:
            return <></>;
    }
}

export function generateSimpleKey(rowIdx: number) {
    return `${rowIdx}-${Date.now()}`;
}

export function isColumnCustomizable(column: ApiColumn): boolean {
    const specialColumnsType = [ListColumnType.DATE, ListColumnType.STATE, ListColumnType.REFERENCE_V2];

    return (
        column.accessibility === Accessibility.VISIBLE &&
        !specialColumnsType.includes(column.type) &&
        !column.relation_key
    );
}

export function isColumnVisible(column: ApiColumn): boolean {
    const specialColumnsType = [ListColumnType.STATE];

    return (
        column.accessibility === Accessibility.VISIBLE &&
        !specialColumnsType.includes(column.type) &&
        !column.relation_key
    );
}

export function isReferenceColumn(column: ApiColumn): boolean {
    return column.type === ListColumnType.REFERENCE_V2;
}

export function isDateColumn(column: ApiColumn): boolean {
    return column.type === ListColumnType.DATE;
}

export interface UseableColumns {
    subColumns: ApiColumn[];
    stateColumns?: StateColumn[];
    customizableColumns: ApiColumn[];
    referenceColumns?: ReferenceColumn[];
    userDefinedVisibleColumns: ApiColumn[];
}

export function useApiDrivenTableColumns(tableId: string, columns: ApiColumn[]): UseableColumns {
    const customizableColumns = columns?.filter(isColumnCustomizable);

    const [value] = useLocalStorage<TableColumnSettings>(localStorageService.getTableSettingsKey(tableId), {});
    const userDefinedVisibleColumns = columns?.filter(isColumnVisible).filter((column) => {
        // Assuming that column is shown by default
        return value[column.key] ?? true;
    });

    const referenceColumns = columns?.filter(
        (column) => column.type === ListColumnType.REFERENCE_V2,
    ) as ReferenceColumn[];
    const subColumns = columns?.filter((column) => column.relation_key);
    const stateColumns = columns?.filter(
        (column) => column.type === FleetOwnerPortalService.ListColumnType.STATE,
    ) as FleetOwnerPortalService.StateColumn[];

    return {
        subColumns,
        stateColumns,
        referenceColumns,
        customizableColumns,
        userDefinedVisibleColumns,
    };
}
