import {useCallback, useMemo} from "react";

import {useI18n} from "common/hooks/useI18n";

import {FleetOwnerPortalService} from "@bolteu/bolt-server-api-fleet-owner-portal";
import {ComboBox, SelectOption} from "@bolteu/kalep-react";

export const ALL_DRIVERS_OPTION_VALUE = -1;

interface Props {
    driverIds: number[] | null;
    onChange: (selectedDrivers: SelectOption[] | null) => void;
    drivers: FleetOwnerPortalService.Driver[];
    loading: boolean;
    label?: string;
    placeholder?: string;
    includeAllOption?: boolean;
    multiple?: boolean;
}

const DriverFilter = ({
    driverIds,
    onChange,
    drivers,
    loading,
    placeholder,
    label,
    includeAllOption = true,
    multiple = false,
}: Props) => {
    const {i18n} = useI18n();

    const onComboBoxChange = useCallback(
        (newValue: SelectOption | SelectOption[] | null) => {
            if (newValue === null) {
                onChange(null);
                return;
            }

            if (multiple) {
                const newDrivers = Array.isArray(newValue) ? newValue : [newValue];
                onChange(newDrivers.length > 0 ? newDrivers : null);
            } else {
                const newDriver = Array.isArray(newValue) ? newValue[0] : newValue;
                onChange(newDriver ? [newDriver] : null);
            }
        },
        [onChange, multiple],
    );

    const driverOptions = useMemo(() => {
        let options: SelectOption[] = includeAllOption
            ? [{value: ALL_DRIVERS_OPTION_VALUE, title: i18n("common.all")}]
            : [];
        if (drivers.length) {
            options = options.concat(
                drivers.map((driver) => ({
                    value: driver.id,
                    title: `${driver.first_name} ${driver.last_name}`,
                })),
            );
        }
        return options;
    }, [drivers, i18n, includeAllOption]);

    const selectedValues = useMemo(() => {
        if (!multiple) {
            return driverOptions.find((option) => option.value === driverIds?.[0]) ?? null;
        }
        return driverOptions.filter((option) => driverIds?.includes(option.value as number)) ?? [];
    }, [driverIds, driverOptions, multiple]);

    return (
        <div className="w-80 shrink-0">
            <ComboBox
                fullWidth
                clearable
                label={label}
                loading={loading}
                multiple={multiple}
                value={selectedValues}
                options={driverOptions}
                onChange={onComboBoxChange}
                placeholder={placeholder ?? i18n("common.all")}
                noResultsMessage={i18n("common.search_no_results")}
            />
        </div>
    );
};

export default DriverFilter;
