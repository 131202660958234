import {useCallback} from "react";
import {useNavigate, useParams} from "react-router-dom";

import {UnreachableCode} from "common/components/util/UnreachableCode";
import {useI18n} from "common/hooks/useI18n";
import {removeStorageItems} from "common/services/authService";
import {UseFleetOwnerForSignupResponse} from "features/account/pages/Verification/types";

import {FleetOwnerVerificationService} from "@bolteu/bolt-server-api-fleet-owner-portal";

import {useVerificationNavigations} from "./useVerificationNavigations";

import UseFleetOwnerForSignupResult = FleetOwnerVerificationService.UseFleetOwnerForSignupResult;

export const useFleetOwnerForSignupResponse = () => {
    const {i18n} = useI18n();
    const navigate = useNavigate();
    const {
        getConfirmationNavigationTo,
        getContinueToAddCompanyNavigationUrl,
        getContinueToCompanRegistrationStepsNavigationUrl,
        getContinueToCompanRegistrationOnboardingNavigationUrl,
        getVerificationTo,
    } = useVerificationNavigations();
    const params = useParams();

    const attemptKey = params.key ?? "";

    const handleUseFleetOwnerForSignupResponse = useCallback(
        (
            response: UseFleetOwnerForSignupResponse,
            isPageOpenCheck?: boolean,
        ): {type: "success"} | {type: "error"; errorMessage: string} => {
            if (!response) {
                return {type: "error", errorMessage: i18n("api.default_error")};
            }
            if (isPageOpenCheck && response.type === UseFleetOwnerForSignupResult.CONTINUE_OTP_FLOW) {
                return {type: "error", errorMessage: i18n("api.default_error")};
            }
            const {type} = response;
            switch (type) {
                case UseFleetOwnerForSignupResult.CONFIRMATION_REQUIRED:
                    navigate(getConfirmationNavigationTo(response.username, attemptKey), {replace: true});
                    break;
                case UseFleetOwnerForSignupResult.CONTINUE_OTP_FLOW:
                    removeStorageItems();
                    navigate(getVerificationTo(attemptKey), {replace: true});
                    break;
                case UseFleetOwnerForSignupResult.CONTINUE_TO_ADD_COMPANY_REGISTRATION:
                    window.location.replace(getContinueToAddCompanyNavigationUrl(response.registration_hash));
                    break;
                case UseFleetOwnerForSignupResult.CONTINUE_TO_COMPANY_REGISTRATION_STEPS:
                    window.location.replace(
                        getContinueToCompanRegistrationStepsNavigationUrl(response.registration_hash),
                    );
                    break;
                case UseFleetOwnerForSignupResult.CONTINUE_TO_COMPANY_REGISTRATION_ONBOARDING_PAGE:
                    window.location.replace(
                        getContinueToCompanRegistrationOnboardingNavigationUrl(response.company_id),
                    );
                    break;
                default:
                    UnreachableCode.never(type);
            }
            return {type: "success"};
        },
        [
            attemptKey,
            getConfirmationNavigationTo,
            getContinueToAddCompanyNavigationUrl,
            getContinueToCompanRegistrationOnboardingNavigationUrl,
            getContinueToCompanRegistrationStepsNavigationUrl,
            getVerificationTo,
            i18n,
            navigate,
        ],
    );

    return {
        handleUseFleetOwnerForSignupResponse,
    };
};
